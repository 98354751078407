.caption1
.secondary-headline1 {
  align-self: stretch;
  position: relative;
  line-height: 110%;
}
.caption1 {
  margin: 0;
  font-size: 1.25em;
  font-weight: 500;
  font-family: inherit;
}

.secondary-headline1 {
  font-weight: 800;
}

.secondary-headline1 {
  margin: 0;
  font-size: 2.25em;
  font-family: inherit;
  color: #4d4d4d;
}
.top1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5em;
  font-family: Inter;
}
.paragraph2 {
  align-self: stretch;
  position: relative;
  font-size: 1.25em;
  line-height: 180%;
  color: #0f172a;
  display: none;
}
.icon-jam-icons-outline-l19 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text10 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container10 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
}
.icon-jam-icons-outline-l20 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button-11 {
  height: 3em;
  border-radius: 8px;
  background-color: #2563eb;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em 0.63em;
  color: #fff;
}
.icon-jam-icons-outline-l21 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text11 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container11 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
}
.icon-jam-icons-outline-l22 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button-21 {
  height: 3em;
  border-radius: 8px;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em 0.63em;
}
.icon-jam-icons-outline-l23 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
  z-index: 0;
}
.button-text12 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container12 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  z-index: 1;
}
.icon-jam-icons-outline-l24 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
  z-index: 2;
}
.text5 {
  position: relative;
  font-size: 0.75em;
  line-height: 140%;
}
.badge4,
.button-31 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.badge4 {
  margin: 0 !important;
  position: absolute;
  top: 0.5em;
  left: 1.5em;
  border-radius: 12px;
  background-color: #da1e28;
  padding: 0.03em 0.34em;
  z-index: 3;
  text-align: center;
  color: #fff;
}
.button-31 {
  height: 3em;
  border-radius: 8px;
  padding: 0.75em 0.5em;
  box-sizing: border-box;
  position: relative;
}
.buttons-group2,
.section-text {
  align-self: stretch;
  justify-content: flex-start;
}
.buttons-group2 {
  display: none;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  color: #2563eb;
}
.section-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5em;
  text-align: left;
  color: #d97706;
  font-family: Roboto;
}
@media screen and (max-width: 1050px) {
  .main-headline1 {
    font-size: 3.63em;
    line-height: 1.09em;
  }
  .secondary-headline1 {
    font-size: 1.81em;
    line-height: 1.1em;
  }
}
@media screen and (max-width: 450px) {
  .caption1 {
    font-size: 1em;
    line-height: 1.13em;
  }
  .main-headline1 {
    font-size: 2.69em;
    line-height: 1.12em;
  }
  .secondary-headline1 {
    font-size: 1.38em;
    line-height: 1.09em;
  }
  .paragraph2 {
    font-size: 1em;
    line-height: 1.81em;
  }
}
