@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

.savers-onboarding-page {
    background-color: #FFFDF1;
    padding: 20px;
    text-align: center;
    font-family: inter;
  }
  
  
  
 
  
  .choice-card {
    background-color: white;
    padding: 10px;
    
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .choice-card.selected {
    background-color: #4caf50;
    color: white;
  }
  
  button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #45a049;
  }

 
  
  h1 {
    color: #333;
  }
  
  .card2 {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-top: 10px;
    height: auto;
  }
  
  .choices {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 10px;
    margin-top: 10px;
  }
  

  .choice {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    transition: background-color 0.3s ease;
  }
  
  .choice:hover {
    background-color: #f0f0f0;
  }
  
  .choice.selected {
    background-color: #4caf50;
    color: #fff;
  }
  
  .next-button,
  .finish-button,
  .go-to-dashboard-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 6px;
    font-size: 16px;
  }
  
  .next-button:hover,
  .finish-button:hover,
  .go-to-dashboard-button:hover {
    background-color: #0056b3;
  }
  
  .go-to-dashboard-button {
    background-color: #28a745;
  }
  
  .go-to-dashboard-button:hover {
    background-color: #218838;
  }
  
  
