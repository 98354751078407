.frame-parent34,
.frame-wrapper9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
}
.frame-parent34 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.25em;
  max-width: 100%;
  text-align: left;
  color: #78350f;
  font-family: Inter;
}
.frame-child23 {
  height: 17.19em;
  width: 72em;
  position: relative;
  border-radius: 8px;
  background-color: #fffef9;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
  display: none;
  max-width: 100%;
}
.vector-icon5 {
  width: 17.31em;
  height: 0.81em;
  position: relative;
  z-index: 2;
}
.third-party-administrators {
  position: relative;
  font-size: 3em;
  line-height: 1.58em;
  font-weight: 600;
  font-family: inherit;
  z-index: 1;
  margin: -0.12em 0 0;
}
.vector-parent1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.saving-for-retirement {
  position: relative;
  font-size: 1em;
  line-height: 1.5em;
  z-index: 1;
}
.saving-for-retirement-is-impor-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 1.19em;
  box-sizing: border-box;
  max-width: 100%;
  color: #717171;
}
.frame-child24 {
  height: 3.25em;
  display: none;
}
.schedule-a-demo3 {
  position: relative;
  font-size: 1em;
  font-weight: 500;
  font-family: Inter;
  color: #78350f;
  text-align: left;
}
.arrow-right-icon4 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.frame-parent36,
.frame-wrapper11 {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.frame-parent36 {
  justify-content: flex-start;
  padding: 0.88em 2em;
  gap: 0.63em;
}
.frame-wrapper11 {
  cursor: pointer;
  border: 1px solid #000;
  padding: 0;
  background-color: transparent;
  justify-content: center;
  z-index: 2;
}
.frame-parent35,
.frame-wrapper10 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-parent35 {
  align-self: stretch;
}
.frame-wrapper10 {
  width: 38.69em;
  padding: 1em 0 0;
  box-sizing: border-box;
  min-width: 38.69em;
}
.logo-frame-icon {
  width: 21.81em;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
  z-index: 1;
}
.frame-parent33,
.rectangle-parent18 {
  align-self: stretch;
  display: flex;
  max-width: 100%;
}
.rectangle-parent18 {
  border-radius: 8px;
  background-color: #fffef9;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.13em 4em 1.88em 1.69em;
  box-sizing: border-box;
  gap: 1.25em;
}
.frame-parent33 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3.56em;
  text-align: left;
  color: #4d4d4d;
  font-family: Inter;
}
@media screen and (max-width: 1200px) {
  .frame-wrapper10,
  .logo-frame-icon {
    flex: 1;
  }
  .rectangle-parent18 {
    flex-wrap: wrap;
    padding-right: 2em;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1050px) {
  .third-party-administrators {
    font-size: 2.38em;
    line-height: 1.61em;
  }
  .frame-wrapper10 {
    min-width: 100%;
  }
}
@media screen and (max-width: 750px) {
 
  .frame-parent33 {
    gap: 1.75em;
  }
}
@media screen and (max-width: 450px) {
  .third-party-administrators {
    font-size: 1.81em;
    line-height: 1.59em;
  }
}
