.frame-child7 {
  align-self: stretch;
  height: 41.38em;
  position: relative;
  background-color: #f4f4f4;
  z-index: 0;
}
.empowering-the-workforce4 {
  align-self: stretch;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
}

.empowering-the-workforce4 {
  height: 2.38em;
  font-size: 1.5em;
}
.putting-individuals-on-the-pat-parent2 {
  width: 42.19em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5.19em;
  max-width: 100%;
}
.request-a-demo5 {
  height: 1.21em;
  position: relative;
  font-size: 1.5em;
  display: inline-block;
  padding-left: 0.83em;
  padding-right: 0.83em;
}
.request-a-demo-wrapper2 {
  width: 16.44em;
  height: 3.25em;
  border-radius: 8px;
  border: 1px solid #000;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.63em;
  white-space: nowrap;
}
.frame-parent12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.25em 0;
  box-sizing: border-box;
  gap: 2.06em;
  min-width: 42.19em;
  max-width: 100%;
}
.image-icon4 {
  height: 22.56em;
  width: 27.63em;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 100%;
}
.frame-parent11 {
  margin: 0 !important;
  position: absolute;
  top: 7.69em;
  left: 8.81em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5.31em;
  max-width: 100%;
  z-index: 1;
}

.rectangle-parent4 {
  align-self: stretch;
  position: relative;
  display: none;
  max-width: 100%;
}
.rectangle-parent4 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.63em;
}

.phone-icon1 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.asset-icon,
.rectangle-parent5 {
  align-self: stretch;
  max-width: 100%;
}

.asset-icon {
  flex: 1;
  overflow: hidden;
  max-height: 100%;
  object-fit: contain;
  position: absolute;
  left: 1em;
  top: -0.5em;
  width: 100%;
  height: 100%;
  transform: scale(1.089);
}
.wrapper-asset {
  align-self: stretch;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-big,
.frame-parent14 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.footer-big {
  height: 27.13em;
  width: 22.69em;
  flex-direction: column;
  align-items: flex-start;
  padding: 4.88em 0 0;
  box-sizing: border-box;
  min-width: 22.69em;
}
.frame-parent14 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 2.81em;
}
.frame-section,
.kiota-tpas {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.frame-section {
  width: 74.5em;
  align-items: flex-start;
  padding: 0 1.25em;
  box-sizing: border-box;
  gap: 5.88em;
  min-height: 77.13em;
  max-width: 100%;
}
.kiota-tpas {
  width: 100%;
  position: relative;
  background-color: #fffdf1;
  overflow: hidden;
  align-items: center;
  gap: 1.25em;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  font-family: Inter;
}
@media screen and (max-width: 1200px) {
  .frame-parent12,
  .image-icon4 {
    flex: 1;
  }
  .frame-parent11 {
    flex-wrap: wrap;
  }
  .frame-section {
    gap: 2.94em;
  }
}
@media screen and (max-width: 1050px) {
  
  .rectangle-parent5 {
    height: auto;
    min-height: 49;
  }
  .asset-icon {
    align-self: stretch;
    width: auto;
  }
  .footer-big {
    flex: 1;
  }
  .frame-parent14 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 750px) {
  .frame-parent12 {
    min-width: 100%;
  }

  .frame-parent14 {
    gap: 1.38em;
  }
  .frame-section {
    gap: 1.44em;
  }
}
@media screen and (max-width: 450px) {
 
  .empowering-the-workforce4 {
    font-size: 1.19em;
  }
  .footer-big {
    padding-top: 3.19em;
    box-sizing: border-box;
    min-width: 100%;
  }
}
