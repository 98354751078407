@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
.education{
    height: max-content;
    overflow: hidden;
    background-color: #FFFDF1;   
}


.top-lefti{
    width: 356px;
    height: 76px;
    top: 222px;
    left: 144px;
    margin-left:120px;
}

.difference {
    text-align: left;
    padding: 20px;
  }

  .difference h3 {
    font-size: 24px; /* Adjusts the size of headings */
    margin-bottom: 10px; /* Adds space below the heading */
  }
  
  .difference p {
    font-size: 16px; /* Sets the base font size for paragraphs */
    line-height: 1.5; /* Increases line height for better readability */
    margin-bottom: 15px;
    text-align: left;
  }
  
  

.top-lefti h1{
    width: 356px;
    height: 76px;
    top: 222px;
    left: 144px;
    font-family: Inter;
    font-size: 36px;
    font-weight: 600;
    line-height: 76px;
    letter-spacing: 0em;
    text-align: left;
    color:#4D4D4D;
}
.top-lefti p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color:#717171;
    width: 360px;
    height: 19px;
    top: 294px;
    left: 144px;
}
.top-lefti img{
    width: 277px;
    height: 12.85px;
    top: 216px;
    left: 145px;
    color: #F64923;
    margin-top: 100px;
}
.hover-calculator{
    margin-left: 650px;
    margin-top: 40px;
    margin-bottom: -140px;
}

  
.learn-main .card{
    width: 1,152px;
    height: auto;
    top: 373px;
    left: 144px;
    padding: 32px;
    gap: 60px;
    margin-top: 200px;
    margin-left: 120px;
    display: flex;
}

.difference {
    width: 500px;
    font-size: 14px;
  }

.difference p{
    padding-left: 20px;
    margin-top: 20px;
}


  .left-card {
    width: 686px;
    transition: max-height 0.3s ease; /* Smooth transition for height change */
}

.left-card h1{
    width: 686px;
    height: 78px;
    font-family: Inter;
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
}
.left-card button{
    background-color: #78350F;
    width: 147px;
    height: 52px;
    padding: 14px, 32px, 14px, 32px;
    border-radius: 8px;
    gap: 10px;

    
}
.right-card img{
    width: 332px;
    height: 266px;
    margin-left: -150px;
    margin-top: 20px;
    margin-bottom: 40px;
}
.card-display h1{
    width: 166px;
    height: 39px;
    top: 811px;
    left: 144px;
    font-family: Inter;
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 100px;
    margin-left: 90px;
    margin-bottom: 60px;
    color: #717171; 
}
.custom-icon{
    width: 20px;
    height: 20px;
    margin-left: 5px;

}
.highlights{
    display: flex;
    flex-wrap: wrap;
    margin-left:70px ;
}
.highlights .card {
    flex:   0   0 calc(33.333% - 20px);
    margin:   10px;

}
.one .card{
    width: 80%;
    height: 70%;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    border-radius: 20px;
}
 .one .card img{
    width: 40%;
    height: 50%;
 }
.one .card h1{
    width: 320px;
    height: 29px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.5px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
}
.one .card p{
    width: 91px;
    height: 17px;
    color: #717171;
}
.schedule-demo-link{
    width: 83px;
    height: 19px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #78350F;
    margin-left: 190px;
}

.two .card{
    width: 80%;
    height: 70%;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    border-radius: 20px;
}
 .two .card img{
    width: 40%;
    height: 50%;
 }
.two .card h1{
    width: 320px;
    height: 29px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.5px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
}
.two .card p{
    width: 91px;
    height: 17px;
    color: #717171;
}



.three .card{
    width: 80%;
    height: 70%;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    border-radius: 20px;
}
 .three .card img{
    width: 40%;
    height: 50%;
 }
.three .card h1{
    width: 340px;
    height: 29px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.5px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
}
.three .card p{
    width: 91px;
    height: 17px;
    color: #717171;
}

.four .card{
    width: 80%;
    height: 70%;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    border-radius: 20px;
}
 .four .card img{
    width: 40%;
    height: 50%;
 }
.four .card h1{
    width: 320px;
    height: 29px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.5px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
}
.four .card p{
    width: 91px;
    height: 17px;
    color: #717171;
}

.five .card{
    width: 80%;
    height: 70%;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    border-radius: 20px;
}
 .five .card img{
    width: 40%;
    height: 50%;
 }
.five .card h1{
    width: 320px;
    height: 29px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.5px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
}
.five .card p{
    width: 91px;
    height: 17px;
    color: #717171;
}
.six .card{
    width: 80%;
    height: 70%;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    border-radius: 20px;
}
 .six .card img{
    width: 40%;
    height: 50%;
 }
.six .card h1{
    width: 320px;
    height: 29px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.5px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
}
.six .card p{
    width: 91px;
    height: 17px;
    color: #717171;
}
.next button{
    width: 145px;
    height: 52px;
    top: 1652px;
    left: 648px;
    padding: 14px, 32px, 14px, 32px;
    border-radius: 8px;
    gap: 10px;
    background-color: #78350F;
    margin-top: 120px;
    margin-left:620px;
}
.rectangle-box{
    height: 227px;
    background-color: #FF6C001A;
    margin-top: 180px;
}
 .rectangle-box .rect{
    width:676px;
    height: 120px;
    gap: 24px;
}
.rectangle-box h2{
    margin-top: -70px;
    text-align: center;
    margin-right: 100px;
    font-family: Inter;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    color: #4D4D4D;
}
.rectangle-box button{
    width: 164px;
    height: 52px;
    padding: 14px, 32px, 14px, 32px;
    border-radius: 8px;
    background-color: #FF6C001A;
    gap: 10px;
    color: #78350F;
    margin-left: 600px;
    margin-top: 20px;
    border: 1px solid #78350F;
}

/* Mobile devices (phones, 600px and down) */
@media only screen and (min-width: 360px) and (max-width: 740px) {
.education{
    height:max-content;
    overflow: hidden;
}
.top-lefti{
    width: 356px;
    height: 76px;
    margin-left:10px;
    display: block;
}
.top-lefti img{
    width: 200px;
    height: 12.85px;
    top: 216px;
    left: 145px;
    color: #F64923;
    margin-top: 20px; 
    margin-left: 10px;
}

.top-lefti h1{
    margin-top: -10px;
    width: 300px;
    height: 76px;
    font-family: Inter;
    font-size: 26px;
    font-weight: 600;
    line-height: 76px;
    letter-spacing: 0em;
    text-align: left;
    color:#4D4D4D;
    margin-left: 10px;
}

.top-lefti p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color:#717171;
    width: 300px;
    height: 19px;
    margin-top: -20px;
    margin-left: 10px;
}

.education .hover-calculator{
    margin-left: 0px;
    margin-top: 80px;
}


.learn-main .card{
    width: 70%;
    height: 100%;
    padding: 32px;
    gap: 60px;
    margin-top: 200px;
    margin-left: 20px;
    display: block;
}


.left-card h1{
    width: max-content;
    height: 78px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
    margin-left: -20px;
}

.left-card button{
    background-color: #78350F;
    width: 147px;
    height: 52px;
    padding: 14px, 32px, 14px, 32px;
    border-radius: 8px;
    gap: 10px;
    margin-top: 10px;
    margin-left: -20px;
}

.right-card img{
    width: 300px;
    height: 266px;
    margin-left: -10px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.card-display h1{
    width: 166px;
    height: 39px;
    font-family: Inter;
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 100px;
    margin-left: 20px;
    margin-bottom: 60px;
    color: #717171; 
}
.highlights{
    margin-left:5px;
    margin-bottom: -160px;
}
.one .card{
    width: 70%;
    height: 70%;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    border-radius: 20px;
}
.one .card img{
    width: 40%;
    height: 50%;
 }
.one .card h1{
    width: 320px;
    height: 29px;
    font-family: Inter;
    font-size: 22px;
    font-weight: 500;
    line-height: 29.5px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
    margin-left: -20px;
}
.one .card p{
    width: 91px;
    height: 17px;
    color: #717171;
    margin-left: -30px;
    margin-top: -10px;
    margin-bottom: -10px;
}
.schedule-demo-link{
    width: 83px;
    height: 19px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #78350F;
    margin-left: 50%;
}
.two .card{
    width: 70%;
    height: 70%;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    border-radius: 20px;
    
}
.two .card img{
    width: 40%;
    height: 50%;
 }
.two .card h1{
    width: 320px;
    height: 29px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.5px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
    margin-left: -20px;
}
.two .card p{
    width: 91px;
    height: 17px;
    color: #717171;
    margin-left: -30px;
    margin-top: -20px;
    margin-bottom: -10px;
}
.three .card{
    width: 70%;
    height: 70%;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    border-radius: 20px;
}
.three .card img{
    width: 40%;
    height: 50%;
 }
.three .card h1{
    width: 340px;
    height: 29px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.5px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
    margin-left: -30px;
}
.three .card p{
    width: 91px;
    height: 17px;
    color: #717171;
    margin-left: -30px;
    margin-top: -10px;
    margin-bottom: -10px;
}
.four .card{
    width: 70%;
    height: 70%;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    border-radius: 20px;
}
.four .card img{
    width: 40%;
    height: 50%;
 }
.four .card h1{
    width: 340px;
    height: 29px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.5px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
    margin-left: -20px;
}
.four .card p{
    width: 91px;
    height: 17px;
    color: #717171;
    margin-left: -30px;
    margin-top: -10px;
    margin-bottom: -10px;
}

.five .card{
    width: 70%;
    height: 70%;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    border-radius: 20px;
}
.five .card img{
    width: 40%;
    height: 50%;
 }
.five .card h1{
    width: 340px;
    height: 29px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.5px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
    margin-left: -20px;
}
.five .card p{
    width: 91px;
    height: 17px;
    color: #717171;
    margin-left: -30px;
    margin-top: -10px;
    margin-bottom: -10px;
}

.six .card{
    width: 70%;
    height: 70%;
    margin-top: 0px;
    display: block;
    margin-bottom: 10px;
    border-radius: 20px;
}

.six .card img{
    width: 40%;
    height: 50%;
 }
.six .card h1{
    width: 340px;
    height: 29px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.5px;
    letter-spacing: 0em;
    text-align: left;
    color: #717171;
    margin-left: -20px;
}
.six .card p{
    width: 91px;
    height: 17px;
    color: #717171;
    margin-left: -30px;
    margin-top: -10px;
    margin-bottom: -10px;
}


.next button{
    border-radius: 8px;
    gap: 10px;
    background-color: #78350F;
    margin-left: 100px;
    margin-top: 200px;
}
.rectangle-box{
    height: 300px;
    width: 410px;
    background-color: #FF6C001A;
    margin-top: 10px;
    margin-left: 0px;
}
 .rectangle-box .rect{
    width:376px;
    height: 120px;
}
.rectangle-box h2{
    text-align: center;
    margin-right: 100px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    color: #4D4D4D;
}
.rectangle-box button{
    width: 164px;
    height: 52px;
    padding: 14px, 32px, 14px, 32px;
    border-radius: 8px;
    background-color: #FF6C001A;
    gap: 10px;
    color: #78350F;
    border: 1px solid #78350F;
    margin-left: 100px;
}

@media only screen and (min-width: 412px)and (max-width: 420px){
    .education{
        height:max-content;
        overflow: hidden;
    }
    .top-lefti{
        width: 356px;
        height: 76px;
        margin-left:10px;
        display: block;
    }
    .top-lefti img{
        width: 230px;
        height: 12.85px;
        top: 216px;
        left: 145px;
        color: #F64923;
        margin-top: 20px; 
        margin-left: 45px;
    }
    
    .top-lefti h1{
        margin-top: -10px;
        width: 350px;
        height: 76px;
        font-family: Inter;
        font-size: 28px;
        font-weight: 600;
        line-height: 76px;
        letter-spacing: 0em;
        text-align: left;
        color:#4D4D4D;
        margin-left: 40px;
    }
    
    .top-lefti p{
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color:#717171;
        width: 300px;
        height: 19px;
        margin-top: -20px;
        margin-left: 40px;
    }
    
    .education .hover-calculator{
        margin-left: 30px;
        margin-top: 80px;
    }
    
    
    .learn-main .card{
        width: 70%;
        height: 100%;
        padding: 32px;
        gap: 60px;
        margin-top: 200px;
        margin-left: 30px;
        display: block;
    }
    .left-card h1{
        width: max-content;
        height: 78px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: left;
        color: #717171;
        margin-left: 10px;
    }
    
    .left-card button{
        background-color: #78350F;
        width: 147px;
        height: 52px;
        padding: 14px, 32px, 14px, 32px;
        border-radius: 8px;
        gap: 10px;
        margin-top: 10px;
        margin-left: 10px;
    }
    
    .right-card img{
        width: 300px;
        height: 266px;
        margin-left: -10px;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    
    .card-display h1{
        width: 166px;
        height: 39px;
        font-family: Inter;
        font-size: 28px;
        font-weight: 500;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 100px;
        margin-left: 90px;
        margin-bottom: 60px;
        color: #717171; 
    }
    .highlights{
        margin-left:5px;
        margin-bottom: -160px;
    }
    .one .card{
        width: 70%;
        height: 70%;
        margin-top: 0px;
        display: block;
        margin-bottom: 10px;
        margin-left: 25px;
        border-radius: 20px;
    }
    .one .card img{
        width: 40%;
        height: 50%;
     }
    .one .card h1{
        width: 320px;
        height: 29px;
        font-family: Inter;
        font-size: 22px;
        font-weight: 500;
        line-height: 29.5px;
        letter-spacing: 0em;
        text-align: left;
        color: #717171;
        margin-left: -20px;
    }
    .one .card p{
        width: 91px;
        height: 17px;
        color: #717171;
        margin-left: -30px;
        margin-top: -10px;
        margin-bottom: -10px;
    }
    .schedule-demo-link{
        width: 83px;
        height: 19px;
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #78350F;
        margin-left: 50%;
    }
    .two .card{
        width: 70%;
        height: 70%;
        margin-top: 0px;
        display: block;
        margin-bottom: 10px;
        margin-left: 25px;
        border-radius: 20px;
        
    }
    .two .card img{
        width: 40%;
        height: 50%;
     }
    .two .card h1{
        width: 320px;
        height: 29px;
        font-family: Inter;
        font-size: 24px;
        font-weight: 500;
        line-height: 29.5px;
        letter-spacing: 0em;
        text-align: left;
        color: #717171;
        margin-left: -20px;
    }
    .two .card p{
        width: 91px;
        height: 17px;
        color: #717171;
        margin-left: -30px;
        margin-top: -20px;
        margin-bottom: -10px;
    }
    .three .card{
        width: 70%;
        height: 70%;
        margin-top: 0px;
        display: block;
        margin-bottom: 10px;
        margin-left: 25px;
        border-radius: 20px;
    }
    .three .card img{
        width: 40%;
        height: 50%;
     }
    .three .card h1{
        width: 340px;
        height: 29px;
        font-family: Inter;
        font-size: 24px;
        font-weight: 500;
        line-height: 29.5px;
        letter-spacing: 0em;
        text-align: left;
        color: #717171;
        margin-left: -30px;
    }
    .three .card p{
        width: 91px;
        height: 17px;
        color: #717171;
        margin-left: -30px;
        margin-top: -10px;
        margin-bottom: -10px;
    }
    .four .card{
        width: 70%;
        height: 70%;
        margin-top: 0px;
        display: block;
        margin-bottom: 10px;
        margin-left: 25px;
        border-radius: 20px;
    }
    .four .card img{
        width: 40%;
        height: 50%;
     }
    .four .card h1{
        width: 340px;
        height: 29px;
        font-family: Inter;
        font-size: 24px;
        font-weight: 500;
        line-height: 29.5px;
        letter-spacing: 0em;
        text-align: left;
        color: #717171;
        margin-left: -20px;
    }
    .four .card p{
        width: 91px;
        height: 17px;
        color: #717171;
        margin-left: -30px;
        margin-top: -10px;
        margin-bottom: -10px;
    }
    
    .five .card{
        width: 70%;
        height: 70%;
        margin-top: 0px;
        display: block;
        margin-bottom: 10px;
        margin-left: 25px;
        border-radius: 20px;
    }
    .five .card img{
        width: 40%;
        height: 50%;
     }
    .five .card h1{
        width: 340px;
        height: 29px;
        font-family: Inter;
        font-size: 24px;
        font-weight: 500;
        line-height: 29.5px;
        letter-spacing: 0em;
        text-align: left;
        color: #717171;
        margin-left: -20px;
    }
    .five .card p{
        width: 91px;
        height: 17px;
        color: #717171;
        margin-left: -30px;
        margin-top: -10px;
        margin-bottom: -10px;
    }
    
    .six .card{
        width: 70%;
        height: 70%;
        margin-top: 0px;
        display: block;
        margin-bottom: 10px;
        margin-left: 25px;
        border-radius: 20px;
    }
    
    .six .card img{
        width: 40%;
        height: 50%;
     }
    .six .card h1{
        width: 340px;
        height: 29px;
        font-family: Inter;
        font-size: 24px;
        font-weight: 500;
        line-height: 29.5px;
        letter-spacing: 0em;
        text-align: left;
        color: #717171;
        margin-left: -20px;
    }
    .six .card p{
        width: 91px;
        height: 17px;
        color: #717171;
        margin-left: -30px;
        margin-top: -10px;
        margin-bottom: -10px;
    }
    
    
    .next button{
        border-radius: 8px;
        gap: 10px;
        background-color: #78350F;
        margin-left: 100px;
        margin-top: 200px;
    }
    .rectangle-box{
        height: 300px;
        width: 410px;
        background-color: #FF6C001A;
        margin-top: 180px;
        margin-left: 0px;
    }
     .rectangle-box .rect{
        width:376px;
        height: 120px;
    }
    .rectangle-box h2{
        text-align: center;
        margin-right: 100px;
        font-family: Inter;
        font-size: 24px;
        font-weight: 700;
        line-height: 44px;
        letter-spacing: 0em;
        color: #4D4D4D;
    }
    .rectangle-box button{
        width: 164px;
        height: 52px;
        padding: 14px, 32px, 14px, 32px;
        border-radius: 8px;
        background-color: #FF6C001A;
        gap: 10px;
        color: #78350F;
        border: 1px solid #78350F;
        margin-left: 100px;
    }
    
    
}

}