.picture-icon7 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  display: none;
  z-index: 0;
}
.icon-iconoir-trekking1 {
  height: 4em;
  width: 4em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.icon5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.category11,
.title3 {
  position: relative;
  line-height: 110%;
}
.category11 {
  align-self: stretch;
  font-size: 1em;
  font-weight: 500;
  display: none;
}
.title3 {
  margin: 0;
  flex: 1;
  font-size: 1.25em;
  font-weight: 700;
  font-family: inherit;
}
.icon-jam-icons-outline-l39 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text21 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container21 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-stat-up3 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge9 {
  height: 1.5em;
  border-radius: 4px;
  background-color: #dcfce7;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  color: #166534;
  font-family: Roboto;
}
.icon-jam-icons-outline-l40 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.title-category3,
.title-container3 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.title-container3 {
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  color: #4d4d4d;
  font-family: Inter;
}
.title-category3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25em;
}
.picture-icon8 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 13.75em;
  flex-shrink: 0;
  object-fit: cover;
  display: none;
}
.paragraph5 {
  align-self: stretch;
  height: 4.88em;
  position: relative;
  font-size: 1em;
  line-height: 160%;
  font-family: Inter;
  color: #4d4d4d;
  display: inline-block;
}
.user-thumb-icon4 {
  height: 4em;
  width: 4em;
  border-radius: 100px;
  object-fit: cover;
}
.category12,
.category13 {
  align-self: stretch;
  height: 1.61em;
  position: relative;
  font-size: 1.13em;
  line-height: 160%;
  display: inline-block;
}
.category13 {
  height: 1.38em;
  font-size: 1em;
  line-height: 140%;
  color: #475569;
}
.details4 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 10.88em;
}
.content3,
.user-card3 {
  align-self: stretch;
  justify-content: flex-start;
  gap: 1em;
}
.user-card3 {
  display: none;
  flex-direction: row;
  align-items: center;
  padding: 1em 0 0;
}
.content3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.icon-jam-icons-outline-l41 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text22 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container22 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l42 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button4 {
  flex: 1;
  border-radius: 8px;
  background-color: #2563eb;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em 0.56em;
  min-width: 6.75em;
}
.icon-jam-icons-outline-l43 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text23 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container23 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l44 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button5 {
  flex: 1;
  border-radius: 8px;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em 0.56em;
  min-width: 6.75em;
  color: #2563eb;
}
.buttons-group5 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1em;
  gap: 1em;
  z-index: 2;
  color: #fff;
}
.icon-jam-icons-outline-l45 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text24 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container24 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-cancel5 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.badge10,
.user-thumb-icon5 {
  margin: 0 !important;
  position: absolute;
  display: none;
}
.badge10 {
  height: 1.5em;
  top: 1em;
  left: 1em;
  border-radius: 4px;
  background-color: #f3e8ff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  z-index: 3;
  color: #6b21a8;
}
.user-thumb-icon5 {
  width: 6em;
  height: 6em;
  top: 10.75em;
  left: calc(50% - 48.25px);
  border-radius: 100px;
  object-fit: cover;
  z-index: 4;
}
.content-box3 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  min-width: 12.5em;
}
.picture-icon9 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  display: none;
  z-index: 0;
}
.icon-iconoir-piggy-bank1 {
  height: 4em;
  width: 4em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.icon6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.category14,
.title4 {
  position: relative;
  line-height: 110%;
}
.category14 {
  align-self: stretch;
  font-size: 1em;
  font-weight: 500;
  display: none;
}
.title4 {
  margin: 0;
  flex: 1;
  font-size: 1.25em;
  font-weight: 700;
  font-family: inherit;
}
.icon-jam-icons-outline-l46 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text25 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container25 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-stat-up4 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge11 {
  height: 1.5em;
  border-radius: 4px;
  background-color: #dcfce7;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  color: #166534;
  font-family: Roboto;
}
.icon-jam-icons-outline-l47 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.title-category4,
.title-container4 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.title-container4 {
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  color: #4d4d4d;
  font-family: Inter;
}
.title-category4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25em;
}
.picture-icon10 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 13.75em;
  flex-shrink: 0;
  object-fit: cover;
  display: none;
}
.paragraph6 {
  align-self: stretch;
  height: 6.5em;
  position: relative;
  font-size: 1em;
  line-height: 160%;
  font-family: Inter;
  color: #4d4d4d;
  display: inline-block;
}
.user-thumb-icon6 {
  height: 4em;
  width: 4em;
  border-radius: 100px;
  object-fit: cover;
}
.category15,
.category16 {
  align-self: stretch;
  height: 1.61em;
  position: relative;
  font-size: 1.13em;
  line-height: 160%;
  display: inline-block;
}
.category16 {
  height: 1.38em;
  font-size: 1em;
  line-height: 140%;
  color: #475569;
}
.details5 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 10.88em;
}
.content4,
.user-card4 {
  align-self: stretch;
  justify-content: flex-start;
  gap: 1em;
}
.user-card4 {
  display: none;
  flex-direction: row;
  align-items: center;
  padding: 1em 0 0;
}
.content4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.icon-jam-icons-outline-l48 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text26 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container26 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l49 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button6 {
  flex: 1;
  border-radius: 8px;
  background-color: #2563eb;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em 0.56em;
  min-width: 6.75em;
}
.icon-jam-icons-outline-l50 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text27 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container27 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l51 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button7 {
  flex: 1;
  border-radius: 8px;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em 0.56em;
  min-width: 6.75em;
  color: #2563eb;
}
.buttons-group6 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1em;
  gap: 1em;
  z-index: 2;
  color: #fff;
}
.icon-jam-icons-outline-l52 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text28 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container28 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-cancel6 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.badge12,
.user-thumb-icon7 {
  margin: 0 !important;
  position: absolute;
  display: none;
}
.badge12 {
  height: 1.5em;
  top: 1em;
  left: 1em;
  border-radius: 4px;
  background-color: #f3e8ff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  z-index: 3;
  color: #6b21a8;
}
.user-thumb-icon7 {
  width: 6em;
  height: 6em;
  top: 10.75em;
  left: calc(50% - 48.25px);
  border-radius: 100px;
  object-fit: cover;
  z-index: 4;
}
.content-box4,
.row1 {
  display: flex;
  align-items: flex-start;
}
.content-box4 {
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-width: 12.5em;
}
.row1 {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: 3em;
  text-align: left;
  color: #0f172a;
  font-family: Roboto;
}
@media screen and (max-width: 750px) {
  .row1 {
    flex-wrap: wrap;
    gap: 1.5em;
  }
}
@media screen and (max-width: 450px) {
  .title3 {
    font-size: 1em;
    line-height: 1.13em;
  }
  .buttons-group5,
  .user-card3 {
    flex-wrap: wrap;
  }
  .title4 {
    font-size: 1em;
    line-height: 1.13em;
  }
  .buttons-group6,
  .user-card4 {
    flex-wrap: wrap;
  }
}
