.book-a-demo-child {
  height: 14.19em;
  width: 90em;
  position: relative;
  background-color: rgba(255, 108, 0, 0.1);
  display: none;
  max-width: 100%;
  z-index: 0;
}
.gain-value-through1 {
  margin: 0;
  position: relative;
  font-size: 2.25em;
  font-weight: 700;
  font-family: inherit;
}
.book-a-demo2 {
  position: relative;
  font-size: 1em;
  font-weight: 500;
  font-family: Inter;
  color: #78350f;
  text-align: left;
}
.demo-btn,
.footer-content {
  display: flex;
  flex-direction: row;
}
.demo-btn {
  height: 3.25em;
  border-radius: 8px;
  border: 1px solid #78350f;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 0.88em 1.81em;
  white-space: nowrap;
}
.footer-content {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-items: flex-start;
  justify-content: flex-start;
}
.text-block-kiota-tools {
  margin: 0 !important;
  position: absolute;
  top: 3.25em;
  left: calc(50% - 337.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5em;
  z-index: 1;
}
.book-a-demo1,
.gain-value {
  align-self: stretch;
  display: flex;
  max-width: 100%;
}
.book-a-demo1 {
  height: 14.19em;
  background-color: rgba(255, 108, 0, 0.1);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3.25em 23.81em 3.44em 23.88em;
  box-sizing: border-box;
  position: relative;
  gap: 0.63em;
  z-index: 2;
}
.gain-value {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  color: #4d4d4d;
  font-family: Inter;
}
@media screen and (max-width: 1050px) {
  .gain-value-through1 {
    font-size: 1.81em;
  }
}

@media (max-width:  600px) {
  .gain-value-through1 {
    font-size:  1.2rem; 
  }
}
@media screen and (max-width: 450px) {
  .gain-value-through1 {
    font-size: 1em;
  }
}
