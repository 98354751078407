.individual-savings .card{
    width: 1152px;
    height: 275px;
    top: 185px;
    left: 144px;
    border-radius: 8px;
    background-color: #FFFEF9;
    margin-top: 60px; 
}


.left-intro img{
    width: 277px;
    height: 12.85px;
    top: 236px;
    left: 171px;

}



.display-images {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
height: 419px;
top: 548px;
left: 144px;
border-radius: 8px;
overflow-x: auto;
margin-top: 100px;
  }
  
  .image1{
    width: 240px;
    height: 304px;
    top: 568.5px;
    left: 183.5px;
    padding: 10px;
    border-radius: 8px;
    border: 1px;
    gap: 16px;
    margin-right: 5px;
    margin-right: 16px;
  }
.display-images h2{
  color: #4D4D4D;
  font-family: Inter;
font-size: 20px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
width: 180px;
height: 48px;

}

.display-images h3{
  width: 267px;
height: 48px;
font-family: Inter;
font-size: 20px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
color: #4D4D4D;
}
.display-images h4{
  width: 249px;
height: 48px;
font-family: Inter;
font-size: 20px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
color:#4D4D4D;

}
.how-it-works{
  margin:40px;
}

.howw-it-works{
  margin-bottom:100px;
  margin-top: -140px;
}
  .image2{
    width: Hug 287px;
    height: Hug 304px;
    top: 568px;
    left: 586.5px;
    padding: 10px;
    border-radius: 8px;
    border: 1px;
    gap: 16px;
    margin-right: 16px;
  

  }
  .image3{
    width: 269px;
    height: 308px;
    top: 564px;
    left: 1022px;
    padding: 10px;
    border-radius: 8px;
    border: 1px;
    gap: 20px;
    margin-right: 16px;
  }

.benefits-of-retirement {
  margin-bottom: 20px;
  margin-top: -40px;

}

  .benefits-of-retirement h1{
    font-family: Inter;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
    color: #4D4D4D;
    width: 707px;
    height: 88px;
    top: 1006px;
    left: 367px;
    margin-left: 300px;
  }

  .bottom-left{


display: flex;

  }
.bottom-right{
  margin-left: 150px;
  margin-top: 40px;
}
  .bottom-images{
    display: flex;
    justify-content:space-evenly;
  }
.how-much-to-save{
  width: 439px;
height: 170px;
margin-top: 20px;
margin-left: -400px;
margin-bottom: -100px;

}
.how-much-to-save h1{
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
   gap: 8px;
  width: 249px;
  height: 22px;
  width: 439px;
height: 22px;
gap: 4px;
  
}

.how-much-to-save h2{
  width: 439px;
height: 52px;
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 25.6px;
letter-spacing: 0em;
text-align: left;
color:#4D4D4D;

}
  .trucck{
    color: purple;
    width: 64px;
height: 64px;

  }

.select-your-investment-strategy{
  width: 434px;
  height: 192px;
  margin-top: 40px;
  margin-left: -80px;
  }

  .select-your-investment-strategy h1{
    width:434px;
height: 18px;
gap: 4px;
font-family: Inter;
font-size: 20px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
color: #4D4D4D;

  }
  .select-your-investment-strategy h2{
    width: 434px;
height: 78px;
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
color: #4D4D4D;

  }
.trophy{
  width: 64px;
height: 64px;
color: purple;
  }

  .pick-a-beneficiary{
    width:434px;
height: 192px;
margin-top: 40px;
  }

  .pick-a-beneficiary h1{
    width:434px;
height:18px;
gap: 4px;
font-family: Inter;
font-size: 20px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
text-align: left;
color: #4D4D4D;

  }

  .pick-a-beneficiary h2{
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 26px;
letter-spacing: 0em;
text-align: left;
color:#4D4D4D;
  }
h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .feedbaki-form{
    width: 577px;
height: 836px;
top: 1133px;
left: 767px;
gap: 7px;

  }
  #feedbackForm {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-weight: bold;
  }
  
  input[type="text"],
  textarea {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  input[type="submit"] {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color:#78350F;
    color: #fff;
    cursor: pointer;
    width: 100%;
  }
  
  input[type="submit"]:hover {
    background-color: #0056b3;
  }
  

  .feedbaki-form{
    width: 577px;
height: 836px;
top: 1133px;
left: 767px;
gap: 7px;

  }


  @media screen and (max-width: 768px) {
    .intro-container {
      width: 100%;
      height: auto;
      border-radius: 0;
    }
  
    .left-intro,
    .right-intro {
      width: 100%;
      margin: 0;
    }
  
    .left-intro h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    .left-intro p {
      font-size: 12px;
    }
  
    .login-container button {
      width: 100%;
      font-size: 14px;
    }
  }
  

  .display-images .image1,
  .display-images .image2,
  .display-images .image3 {
    width: calc(33% - 20px);
    padding: 10px;
    border-radius: 8px;
    border: 1px solid;
    margin-bottom: 20px;
  }