.dialog-box-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .dialog-box {
    background-color: white;
    padding: 20px;
    border-radius: 10px; /* Add border-radius for rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25); /* Add box-shadow for a card-like effect */
    max-width: 400px; /* Limit the maximum width */
    width: 100%; /* Make the dialog box responsive */
  }
  
  .dialog-box-content {
    text-align: center;
  }
  
  .dialog-box-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  
  .dialog-box-buttons button {
    margin: 0 10px;
    padding: 8px 16px;
    border-radius: 5px; /* Add border-radius for rounded buttons */
    font-size: 14px;
    cursor: pointer;
  }

  