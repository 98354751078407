.schedule-demo-button {
    display: flex;
    height: 52px;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #2376ff;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    color: #78350F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  