/* RetirementStory.css */

/* Container for the entire RetirementStory component */
.retirement-story {
  font-family: Arial, sans-serif;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  overflow: hidden;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loading-container {
  background-color: #FFFDF1;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

/* Error message */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

/* Result section */
.result-container {
  display: flex;
  flex-direction: row; /* Align items in a row */
  /* justify-content: center; */
}

.net-worth-container {
  display: flex;
  flex-direction: row;
  gap: 500px; /* Add space between the two net worth sections */
}

.net-worth-today,
.net-worth-retirement {
  margin-bottom: 10px;
}

.net-worth-today h3,
.net-worth-retirement h3 {
  font-size: 24px;
  margin: 0;
}

.tooltip-icon {
  cursor: pointer;
  margin-left: 5px;
}

/* Chart section */
.chart-container {
  margin-top: 80px; /* Add margin to separate from the rest */
  background-color: white; /* Light gray background */
  padding: 20px; /* Add padding to create a card-like appearance */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 1000px;
  
}

/* Mobile queries */
@media screen and (max-width: 767px) {
  .net-worth-container {
    flex-direction: row; /* Align items in a column */
    gap: 80px; /* Add space between the two net worth sections */
  }

  .result-container {
    flex-direction: row; /* Align items in a column */
    margin: auto;
  }

  .chart-container {
    margin-top: 60px; /* Add margin to separate from the rest */
    width: 90%;
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
  }
  .logo {
    margin-left: 3em;
  }
  .vector {
    margin-left: 20em;
  }
}

.retirement-story {
  background-color: #FFFDF1;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  overflow: hidden;
}

.calculator-brand img {
  width: 4em;
  margin: auto;
  /* margin-left: 50px; */
}
.story-title h1{
  margin: auto;
  margin-top: -1.5em;
}
h1 {
  text-align: left;
  font-family: Inter;
  font-size: 25px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0em;
}

h2 {
  text-align: left;
  font-family: Inter;
  font-size: 19px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0em;
  color: #78350F;
  width: 100%;
  margin-top: -2em;
  margin-left: -5em;
}

p {
  font-family: Inter;
  font-size: 18px;
  line-height: 2em
}

.dashInput {
  position: relative;
}

/* ul {
  margin: 0;
  padding: 0 ;
  display: block;
}

ul li {
  position: absolute;
  list-style: none;
  width: 20px;
  height: 20px;
  background: #bfe616;
  border-radius: 50%;
  transition: 0.5s;
  cursor: pointer;
}

ul li:hover {
  background: #86ff3b;
  box-shadow: 0 0 0 4px #333;
              0 0 0 6px #86ff3b;
}

ul li .content {
  position: absolute;
  bottom: 55px;
  width: 400px;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  transform: translateX(-50%) translateY(-50px);
}

ul li .content::before {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  background: #fff;
  bottom: 0;
  left: 194px;
  transform: rotate(45deg);

}

ul li .content h3 {
  margin: 0;
  padding: 0;
}

ul li:hover .content {
  visibility: visible;
  opacity: 1;
  transform: translateX(-2%) translateY(0px);
} */

.popup {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  display: none;
}

.input-with-popup:hover .popup,
.input-with-popup.clicked .popup {
  display: block;
}

input[type="text"]{
  width: 2px;
  height: 2px;
  border: none;
  border-radius: 50px;
  /* border-bottom: 1px solid rgb(223, 159, 21); */
  /* background-color: #f0ce11; */
  margin: 5px;
  padding: auto;
  /* padding-left: 2em; */
  font-size: 16px;
  position: relative;
  /* z-index: 1111; */
}

/* input[type="text"]:hover {
  border-bottom: 1px solid #d0e60e;
  background-color: rgb(135, 136, 131);
  cursor: pointer;
  content: attr(data-tooltip);
  position: absolute;
  background-color: #888282;
  color: #d13939;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1;
  transition: border-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
} */
input[type="text"]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  background-color: #333;
  color: #d13939;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1;
}
input[type="text"].show-info::after {
  content: attr(data-info);
  position: absolute;
  background-color: #333;
  color: #c94141;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
}
input[type="text"]:focus {
  outline: rgb(32, 29, 25);
  border-color: rgb(109, 77, 8);
  transition: border-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.input-field {
  position: relative;
}

input[type=text]:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1;
}

input[type=text].show-info::after {
  content: attr(data-info);
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  white-space: normal;
  max-width: 300px;
}
input[type=text]::-webkit-inner-spin-button,
input[type=text]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

input[type="number"]{
  width: 90px;
  border: none;
  border-radius: 2px;
  border-bottom: 1px solid rgb(223, 159, 21);
  background-color: #FFFDF1;
  margin: auto;
  padding-left: 2em;
  font-size: 16px;
  transition: 0.1s ease;
  z-index: 1111;
}

input[type="number"]:hover {
  border-bottom: 1px solid #d0e60e;
  /* background-color: rgb(135, 136, 131); */
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}
input[type="number"]:focus
input[type="number"]:valid {
  outline: rgb(214, 136, 18);
  border-color: rgb(177, 136, 48);
  border: 4px solid rgb(177, 136, 48);
  transition: border-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}
input[type="number"]:focus + .labelline
input[type="number"]:valid + .labelline {
  outline: rgb(214, 136, 18);
  border-color: rgb(177, 136, 48);
  color: #1ea81e;
  transform: translate(-15px, -16px);
  transition: border-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.labelline {
  position: absolute;
  font-size: 1em;
  color: #78350F;
  padding: 0 25px;
  margin: 0 20px;
  background-color: #4D4D4D;
  transition: 0.2 ease;
}

.vector {
  margin-top: 1em;
  margin-left: 8px;
  margin-bottom: -1em;
}
.story-title3 h1{
  /* margin-left: 460px; */
  margin: auto;
}

.story-title2 h2{
  /* margin-left: 480px; */
  margin: auto;
}
button1 {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #78350F;
  color: white;
  font-size: 18px;
  cursor: pointer;
}
.calculate-btn {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #78350F;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.calculate-btn:hover {
  background-color: #1ea81e;
}

.result-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* padding-left: 5em; */
  /* background-color: #78350F; */
  max-width: 250px;
}
.net-worth-container {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  max-width: 200px;
  /* margin: 0 auto 10px; */
  /* background-color: #0039cb; */
}


.net-worth-retirement {
  background-color: #84f084;
  padding: 15px;
  margin: 10px;
  text-align: center;
  border-radius: 10px;
  position: relative;
  /* width: 150px; */
}
.net-worth-today {
  background-color: #84f084;
  padding: 15px;
  margin: 10px;
  text-align: center;
  border-radius: 10px;
  position: relative;
  /* width: 170px; */
}
.tooltip-icon {
  margin-left: 5px;
  cursor: help;
}

.net-worth-today h3,
.net-worth-retirement h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.net-worth-today p,
.net-worth-retirement p {
  font-size: 16px;
  color: #666;
}

.chart-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  height: 670px;
}

.chart-wrapper {
  max-width: 800px;
  height: 300px;
}
.modal-title {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}


/* .modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  position: relative;
  margin: 0 auto; 
} */
/* Ensure consistent styling for input fields */
.reg-card input,
.reg-card select {
  width: 100%; /* Ensures input fields take up the full width of their container */
  padding: 10px; /* Consistent padding */
  margin-bottom: 20px; /* Consistent bottom margin */
  border-radius: 5px; /* Consistent border radius */
  border: 1px solid #ccc; /* Consistent border */
  font-size: 16px; /* Consistent font size */
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
}

.reg-card .input-wrapper {
  position: relative;
  padding-top: 25px; /* Ensures space for the label */
  margin-bottom: 30px; /* Consistent margin bottom for input wrapper */
}

.reg-card label {
  display: block; /* Makes sure the label is a block element */
  position: absolute; /* Positions the label absolutely within the input wrapper */
  top: 0; /* Aligns the label to the top of the input wrapper */
  left: 10px; /* Aligns the label horizontally */
  background: white; /* Adds a background to the label for better readability */
  padding: 0 5px; /* Adds some padding around the text */
  font-size: 14px; /* Consistent font size for labels */
}

.reg-card button {
  padding: 8px 16px; /* Padding for the button */
  border-radius: 5px; /* Border radius for the button */
  border: none; /* No border for the button */
  color: white; /* Button text color */
  background-color: #78350F; /* Button background color */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 14px; /* Consistent font size for button text */
  width: auto; /* Automatic width */
  margin: 25px auto; /* Center the button */
  display: block; /* Display the button as a block element */
}

.register-btn {
  transition: all 0.5s ease; /* Smooth transition effect */
}

.register-btn:hover {
  border: 2px solid yellow; /* Border color on hover */
  box-shadow: 0 0 10px yellow; /* Box shadow effect on hover */
}

/* Modal content form styling */
.modal-content form {
  display: flex;
  flex-direction: column; /* Flex column layout */
  gap: 0.5rem; /* Gap between flex items */
}

.modal-content input {
  padding: 0.5rem; /* Padding for modal content inputs */
  font-size: 1rem; /* Font size for modal content inputs */
  border: 1px solid #ccc; /* Border for modal content inputs */
  border-radius: 4px; /* Border radius for modal content inputs */
}

.join-waitlist-btn {
  padding: 0.5rem 1rem; /* Padding for join waitlist button */
  font-size: 1rem; /* Font size for join waitlist button */
  background-color: #78350F; /* Background color for join waitlist button */
  color: #fff; /* Text color for join waitlist button */
  border: none; /* No border for join waitlist button */
  border-radius: 4px; /* Border radius for join waitlist button */
  cursor: pointer; /* Pointer cursor on hover */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 22px;
  font-weight: bold;
  color: #aaa;
  cursor: pointer;
}

.close-button:hover,
.close-button:focus {
  color: #333;
  text-decoration: none;
  cursor: pointer;
}


  @media only screen and (min-width: 360px) and (max-width: 740px) {
    .retirement-story {
      overflow: hidden;
    }
    .calculator{
      margin-left: 14px;
      width:300px;
      margin-top: 2em;
    }
    .calculator.h1{
      font-size: 8px;
    }

    .calculator h1{
      width:max-content;
      margin-left: -20px;
      color: #4D4D4D;
      font-family: inter;
      margin-top: 15px;
      font-size: 10px;
    }
    .current-age{
      color:#4D4D4D;
      font-family: inter;
    }
    .retirement-age{
      color:#4D4D4D;
      font-family: inter;
    }
    .monthly-savings{
      color:#4D4D4D;
      font-family: inter;
    }
    .annual-return{
      color:#4D4D4D;
      font-family: inter;
    }
    .result{
      margin-top: 60px;
    }
   .input-group .annual-return{
    margin-bottom: 30px;
    
   }
    .calculate-btn{
      background-color:#78350F;
      margin: auto;
      width:100%;
    }
    
    .calculator-brand img{
      margin-left: -3em;
      margin-top: 40px;
      padding-bottom: -14em;
      width: 6em;
    }
    .vector {
      margin-top: -1em;
      margin-left: 3em;
      margin-bottom: 0em;
    }
    .story-title h1{
      font-family: Inter;
      font-weight: 600;
      letter-spacing: 0em;
      line-height: 50px;
      text-align: center;
      width: 280px;
      height: 50px;
      font-size: 18px;
      color: #4D4D4D;
      margin-left: 10px;
      margin-top: -10px;
      padding: 0;
    }
  
    .story-title h1{
      font-family: Inter;
      font-weight: 600;
      letter-spacing: 0em;
      line-height: 50px;
      text-align: center;
      width: 280px;
      height: 50px;
      font-size: 18px;
      color: #4D4D4D;
      margin-left: 10px;
      margin-top: -10px;
      padding: 0;
    }
  
    .story-paragraph {
      color: #4D4D4D;
      font-size: 12px;
      line-height: 10px;
      width: 338px;
      text-align:left;
      margin-left:20px;
      margin-bottom: -200px;
    }
    .story-paragraph2{
      margin-left: 20px;
    }
   .story-paragraph p {
    width:  90%;
      font-family: Inter;
      font-size: 16px;
      margin-left:5px;
      line-height: 3em
    }
    
    .calculator h1 {
      width: max-content;
      color: #4D4D4D;
      font-family: inter;
      margin-top: -1px;
      font-size: 12px;
      margin-left: 30px;
  }
  .net-worth-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 800px;
}
.story-title3 h1 {
  margin-left: -40px;
  margin-top: 210px;
  text-align: center;
  font-size: 20px;
}
.story-title2 h2 {
  margin-left: 30px;
  text-align: left;
}

@media only screen and (min-width: 412px)and (max-width: 420px){
  .story-title3 h1 {
    margin-left: -40px;
    margin-top: 210px;
    text-align: center;
    font-size: 20px;
  }
  .story-title2 h2 {
    margin-left: 10px;
    text-align: left;
  }
}
  } 