@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --body-regular-body-3: Inter;
  --font-inherit: inherit;

  /* font sizes */
  --body-regular-body-3-size: 14px;
  --heading-headline-4-size: 20px;
  --font-size-base: 16px;
  --font-size-11xl: 30px;
  --font-size-5xl: 24px;

  /* Colors */
  --color-linen: #f1e9da;
  --text-gray-300: #d9dbe1;
  --default-white: #fff;
  --color-midnightblue: #170f49;
  --color-darkslategray: #263238;
  --neutral-grey: #717171;
  --neutral-d-grey: #4d4d4d;
  --color-black: #000;

  /* Gaps */
  --gap-5xl: 24px;
  --gap-xs: 12px;

  /* Border radiuses */
  --br-5xs: 8px;
}
