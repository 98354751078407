.frame-child13 {
  align-self: stretch;
  height: 41.38em;
  position: relative;
  background-color: #f4f4f4;
  z-index: 0;
}

.putting-individuals-on7 {
  align-self: stretch;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
}
.putting-individuals-on7 {
  height: 1.21em;
  font-size: 3em;
  padding-right: 0.33em;
}

.putting-individuals-on-the-pat-parent5 {
  width: 42.19em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5.19em;
  max-width: 100%;
}


.frame-parent23 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.25em 0;
  box-sizing: border-box;
  gap: 2.06em;
  min-width: 42.19em;
  max-width: 100%;
}

.frame-parent22,
.rectangle-parent9 {
  justify-content: flex-start;
  max-width: 100%;
}
.frame-parent22 {
  margin: 0 !important;
  position: absolute;
  top: 7.69em;
  left: 8.81em;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5.31em;
  z-index: 1;
}
.rectangle-parent9 {
  align-self: stretch;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  gap: 0.63em;
}
.kiota-logo-14 {
  height: 2.5em;
  width: 6.67em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.kiota-logo-1-container {
  width: 24.69em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 1.25em;
  box-sizing: border-box;
  max-width: 100%;
}
.pattern-icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  margin-top:0.5px;
}
.grid-icons-dropdown-frame,
.kiota-logo-frame {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 3.63em;
  max-width: 100%;
}
.kiota-logo-frame {
  align-items: flex-start;
  gap: 5.94em;
}
.frame-child14 {
  height: 4.25em;
  width: 18.88em;
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.05);
  display: none;
}
.circle-1-icon {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  min-height: 1.5em;
  z-index: 1;
}
.retirement-savings-plan {
  flex: 1;
  position: relative;
  font-size: 1.13em;
  letter-spacing: -0.03em;
  z-index: 1;
}
.group-div,
.unlockframe {
  display: flex;
  flex-direction: row;
}
.group-div {
  width: 18.88em;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.05);
  align-items: center;
  justify-content: flex-start;
  padding: 1.38em 2.88em 1.38em 1.56em;
  box-sizing: border-box;
  gap: 0.94em;
  white-space: nowrap;
  z-index: 1;
}
.unlockframe {
  width: 32.5em;
  align-items: flex-start;
  justify-content: flex-end;
  max-width: 100%;
}
.features-that-are {
  margin: 0;
  height: 1.21em;
  flex: 1;
  position: relative;
  font-size: 3em;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.benefitsarea {
  width: 44.25em;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0.69em 0 0;
  box-sizing: border-box;
  color: #4d4d4d;
}
.benefitsarea,
.feature-frame,
.retirement-programs-group {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.feature-frame {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 6.81em;
}
.retirement-programs-group {
  width: 76.13em;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 2em 0 1.25em;
  box-sizing: border-box;
  min-height: 46.81em;
  flex-shrink: 0;
  text-align: left;
  color: #191a15;
  font-family: Inter;
}
.rectangleframe-child {
  height: 33.81em;
  width: 90em;
  position: relative;
  background-color: #f5f7fa;
  display: none;
  max-width: 100%;
}
.framefeatures,
.rectangleframe {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}
.rectangleframe {
  flex: 1;
  background-color: #f5f7fa;
  align-items: flex-end;
  justify-content: center;
  padding: 3em 0;
}
.framefeatures {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0.81em;
  flex-shrink: 0;
}
.kiota-login-child {
  align-self: stretch;
  position: relative;
  background-color: #fffdf1;
  height: 5.81em;
  display: none;
}
.unlock-frame-child {
  height: 33.81em;
  width: 90em;
  position: relative;
  background-color: #f5f7fa;
  display: none;
  max-width: 100%;
}
.kiota-login,
.unlock-frame {
  display: flex;
  box-sizing: border-box;
}
.unlock-frame {
  background-color: #f5f7fa;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding: 2.94em 0;
  max-width: 100%;
  flex-shrink: 0;
}
.kiota-login {
  width: 100%;
  height: 47.13em;
  position: relative;
  background-color: #fffdf1;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2.44em 0 0;
  gap: 0.69em;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  font-family: Inter;
}

@media screen and (max-width: 2000px) {
  .kiota-login {
    height: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .frame-parent23,
 
  .frame-parent22 {
    flex-wrap: wrap;
  }
  .feature-frame {
    gap: 3.38em;
  }
  .kiota-login {
    height: auto;
  }
}
@media screen and (max-width: 825px) {
  .putting-individuals-on7 {
    font-size: 2.38em;
  }
  .frame-parent23 {
    min-width: 100%;
  }
  .grid-icons-dropdown-frame {
    gap: 1.81em;
  }
  .kiota-logo-frame {
    gap: 2.94em;
  }
  .features-that-are {
    font-size: 2.38em;
  }
  .feature-frame {
    gap: 1.69em;
  }
  .rectangleframe,
  .unlock-frame {
    padding-top: 1.94em;
    padding-bottom: 1.94em;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .putting-individuals-on7 {
    font-size: 1.81em;
  }
  
  .kiota-logo-frame {
    gap: 1.5em;
  }
  .group-div {
    padding-right: 1.25em;
    box-sizing: border-box;
  }
  .features-that-are {
    font-size: 1.81em;
  }
}
