
.demo-navigate{
  overflow: hidden;
  height: 1200px;
  top: 1399px;
  left: 10649px;
}
.kiota-demo-child {
  position: absolute !important;
  top: 242px !important;
  left: 736px !important;
  border-radius: 12px !important;
  background-color: #fffef9 !important;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1) !important;
  width: 530px !important;
  height: 580px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  color: aqua;
}

.frame-child {
  position: relative !important;
  background-color: #f4f4f4 !important;
  width: 1440px !important;
  height: 662px !important;
  z-index: 0 !important;
}
.empowering-the-workforce,
.putting-individuals-on {
  position: relative !important;
  display: inline-block;
  width: 659px;
  height: 58px;
  flex-shrink: 0;
}
.empowering-the-workforce {
  font-size: var(--font-size-5xl);
  width: 675px;
  height: 57px;
}
.putting-individuals-on-the-pat-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 83px;
}

.request-a-demo {
  font-family: Inter;
  font-size: 36px;
  font-weight: 600;
  line-height: 76px;
  letter-spacing: 0em;
  text-align: left;
  color: hsba(0, 0%, 30%, 1);
}
.request-a-demo-wrapper {
  width: 281px;
  height: 76px;
  top: 168px;
  left: 144px;
}
.frame-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 33px;
}
.image-icon {
  width: 277px;
  height: 12.85px;
  top: 231px;
  left: 145px;
  color:hsba(11, 86%, 96%, 1);
}

.frame-parent,
.rectangle-parent {
  position: absolute;
  justify-content: flex-start;
}
.frame-parent {
  margin: 0 !important;
  top: 123px;
  left: 141px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 85px;
  z-index: 1;
}
.rectangle-parent {
  top: 151px;
  left: 0;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  font-size: 48px;
  color: var(--color-black);
}
.answer-a-few {
  position: absolute;
  top: 649px;
  left: 1721px;
  font-size: var(--font-size-base);
  display: inline-block;
  width: 578px;
}
.you-are-in {
  width: 364px;
  height: 41px;
  font-family: Inter;
  font-size: 30px;
  font-weight: 500;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
  color:#717171;
}
.undraw-online-media-re-r9qv-1-icon {
  position: relative;
  width: 281px;
  height: 274px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.kiota-powers-hundreds {
  margin: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color:hsba(0, 0%, 44%, 1);
  width: 426px;
  height: 252px;

}

.flexible-solutions-whether-yo {
  padding-left: 21px;
  width: 426px;
  height: 252px;
  margin-top: -140px;
}
.flexible-solutions-whether{
  font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0em;
text-align: left;

}
.kiota-powers-hundreds-container {
  position: relative;
  font-size: var(--font-inherit);
  line-height: 176.52%;
  font-family: var(--font-inherit);
  display: inline-block;
  width: 426px;
}
.you-are-in-great-company-parent {
  position: absolute;
  top: 272px;
  left: 144px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 36px;
}
.well-connect-you {
  position: relative;
  display: inline-block;
}
.your-demo-with {
  margin: 0;
  font-size: inherit;
  font-weight: 500;
  font-family: inter;
  width: 496px;
}

.your-demo-with-kiota-is-waitin-parent {
  width: 493px;
  height: 87px;
  gap: 17px;
}

.your-demo-with-kiota-is-waitin-parent h1{
  width: 496px;
  height: 36px;
  font-family: Inter;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #717171;
  margin-left: 30px;
}
.your-demo-with-kiota-is-waitin-parent p{
color: #717171;
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
margin-left: 30px;
}

.by-requesting-a {
  width: 496px;
  height: 34px;
  font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
color: #717171;
margin-top: 30px;
margin-left: 30px;
}

.frame-divv{
  margin-top: 40px;
  margin-left: -290px;
}
.frame-item1 {
  background-color: transparent;
}
.frame-parent101 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);

}


.frame-parent1 button{
background-color: #78350F;
width: 503px;
height: 48px;
padding: 22px, 32px, 22px, 32px;
border-radius: 5px;
gap: 10px;
margin-top:20px;
margin-left: 310px;
color: #F1E9DA;


}

.demo-container {
  position: relative;
  width: 560px;
  height: 131px;
  top: 242px;
  left: 736px;
  border-radius: 12px;

  
}
.request-a-demo1 {
  margin-top: -30px;
  position: absolute;
  top: 168px;
  left: 144px;
  font-size: 36px;
  line-height: 76px;
  font-weight: 600;
  font-family: inherit;
  color: var(--neutral-d-grey);
}
.vector-icon {
  position: absolute;
  width: 277px;
height: 12.85px;
top: 231px;
left: 145px;

}
.if-youre-currently {
  position: relative;
  display: inline-block;
  width: 944px;
  flex-shrink: 0;
}
.if-youre-currently-enrolled-i-wrapper {
  position: relative;
  top: 1440px;
  left: 0;
  background-color: var(--color-linen);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 31px 248px;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--neutral-grey);
  font-family: var(--body-regular-body-3);
}
.pattern-icon {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.asset-4-1-child,
.layer-2,
.pattern-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.asset-4-1-child {
  height: 68.38%;
  bottom: 31.62%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.asset-4-1,
.--logo-1 {
  overflow: hidden;
  flex-shrink: 0;
}
.asset-4-1 {
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  left: calc(50% - 720px);
  width: 1440px;
  height: 117px;
  z-index: 0;
}


.frame-parent2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 35px;
  z-index: 1;
}



.col-1,
.list-items {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-items {
  gap: var(--gap-xs);
  font-size: var(--body-regular-body-3-size);
}
.col-1 {
  gap: var(--gap-5xl);
}


.list-items1 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--body-regular-body-3-size);
  color: var(--font-inherit);
}

.input-field-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--default-white);
  opacity: 0.2;
}
.essential-icons-send {
  position: absolute;
  height: 45%;
  width: 7.06%;
  top: 27.5%;
  right: 4.71%;
  bottom: 27.5%;
  left: 88.24%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.your-email-address {
  position: absolute;
  top: 22.5%;
  left: 4.71%;
  line-height: 20px;
}
.email-input-field {
  position: relative;
  width: 255px;
  height: 40px;
  font-size: var(--body-regular-body-3-size);
  color: var(--text-gray-300);
}
.col-3 {
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  color: var(--default-white);
}


.links {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
}
.links {
  overflow: hidden;
  gap: 30px;
  z-index: 2;
  font-size: var(--heading-headline-4-size);
  color: var(--color-midnightblue);
}

.kiota-logo-1-wrapper img {
  position: absolute;
  top: 69px;
  left: 144px;
  width: 106px;
  height: 40px;
}
.frame-item{
  
  margin-top: -30px;
  margin-left:310px;
  background-color: rgba(236, 236, 236, 1);

}


.kiota-demo {
  position: relative;
  background-color: #fffdf1;
  width: 100%;
  height: 1536px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-11xl);
  color: var(--neutral-grey);
  font-family: var(--body-regular-body-3);
}



.currently-enrolled .card{
width: 100%;
height: 100px;
top: 1440px;
padding: 31px, 248px, 31px, 248px;
gap: 10px;
margin-top: 900px;
background-color: #F1E9DA;
}

.currently-enrolled .card p{
  width: 944px;
height: 38px;
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: center;
color:#717171;
margin-left: 160px;
}

@media only screen and (max-width:  480px) {
  /* CSS rules for mobile phones */
  .demo-navigate{
    background-color: red;
  }
  .kiota-logo-1-wrapper img {
    width: 96px;
    height: 40px;
    margin-left: -100px;
    margin-top: -30px;
  }
  .request-a-demo1 {
    font-size: 20px;
    height: 60px;
    width: 181px;
    text-align: left;
    margin-left: -100px;
    margin-top: -90px;
   }
  .vector-icon {
    width: 157px;
    height: 6.85px;
    margin-left: -100px;
    margin-top: -100px;
  }

  .you-are-in{
    margin-left: -100px;
    font-size: 18px;
    margin-top: -120px;
  }
  .undraw-online-media-re-r9qv-1-icon {
    position: relative;
    width: 281px;
    height: 274px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
    margin-left: -120px;
    margin-top: -20px;
}
.kiota-powers-hundreds-container {
  position: relative;
  font-size: var(--font-inherit);
  line-height: 176.52%;
  font-family: var(--font-inherit);
  display: inline-block;
  width: 426px;
  margin-left: -120px;
}
.kiota-powers-hundreds-container p {
   font-size: 16px;
   width: 280px;
   margin-left: 20px;
}
.flexible-solutions-whether {
  font-family: Inter;
  font-size: 14px;
  width: 285px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 3px;
}
.currently-enrolled .card {
  width: 100%;
  margin-left: 20px;
  overflow: hidden;
  height: 100px;
  top: 1440px;
  padding: 31px, 248px, 31px, 248px;
  gap: 10px;
  margin-top: 900px;
  margin-left: -10px;
  background-color: #F1E9DA;
}
.currently-enrolled .card p {
  width: 350px;
  height: 48px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #717171;
  margin-left: -18px;
  margin-top: 0px;
}
}

/* Section on the Right side in desktop view */

/* .your-demo-with-kiota-is-waitin-parent h1 {
  width: 496px;
  height: 36px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #717171;
  margin-left: -700px;
  margin-top: 600px;
}

.kiota-demo-child {
 
  top: 242px !important;
  left: 736px !important;
  border-radius: 12px !important;
  background-color:#F1E9DA !important;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1) !important;
  width: 330px !important;
  height: 320px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  color: aqua;
  margin-left: -720px;
  margin-top: 600px;
}

.your-demo-with-kiota-is-waitin-parent p {
  color: #717171;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: -710px;
  margin-top: -10px;
  width: 320px;
}
.by-requesting-a {
  width: 320px;
  height: 34px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #717171;
  margin-top: 10px;
  margin-left: -710px;
}
.frame-parent1{
  margin-left: -720px;
  margin-bottom: 300px;
}

.frame-item{
  width: 40px;
}

} */