.about-page{
  background-color:#FFFDF1;
  margin-top: 0px;
  overflow: hidden;
}


.header h1{
    width: 157px;
    height: 44px;
    font-size: 30px;font-family: Inter;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    color: #4D4D4D;
    margin-left: 80px;
    margin-top: 20px;
}

.vector img{
    width: 377px;
    height: 12.85px;
    color:#F64923;
    margin-left: 60px;
}

.aboutL{
    display: flex;
    flex-basis: 45%;
    gap: 250px;
    padding: 160px, 80px, 160px, 80px;
}
.aboutL img{
    width: 719px;
    height: 609px;
    top: 283px;
    left: 171px;
    border-radius: 8px;
    border: 8px;
    background-color: rgba(255, 253, 241, 1);
    margin-left: 80px;
    margin-top: 20px;
}

.right{
    padding-right: auto;
    margin-left: -80px;
   
}

.right h1{
    width: 122px;
    height: 174px;
    top: 333px;
    left: 1002px;
    font-family: Inter;
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: center;
    margin-left: -10px;
}

.right p{
    width: 302px;
    height: 138px;
    top: 524px;
    left: 1002px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color:#717171;
}


.bottom-container{
    background-color: #FF6C001A;
    width: 1,440px;
    height:227px;
    top: 1837px;
    gap: 10px;   
}

.bottom-container .book-demo{
    margin-top: 10px;
}
.book-demo h1{
    width: 676px;
    height: 44px;
    font-family: Inter;
font-size: 36px;
font-weight: 700;
line-height: 44px;
letter-spacing: 0em;
text-align: center;
margin-bottom: 40px;
margin-left: 280px;
margin-top: 24px;

    
}

.book-demo{
   
    width: 676px;
    height: 120px;
    gap: 24px;
    margin-top: 120px;
    
}

.book-demo button{
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color:#78350F;
    background-color: #FF6C001A;
    border-color: #FF6C001A;
    margin-left: 545px;
}

.mission{
    gap:200px;
    display:block;
 
}
.mission h1{
    width: 180px;
    height: 58px;
    top: 1055px;
    left: 136px;
    font-family: Inter;
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: left;
    color:#4D4D4D;
    margin-left: 80px;

}

.mission p{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 80px;
    color:#717171;


    
}

.mission img{
    display: flex;
    width: 466px;
    height: 311px;
    top: 1038px;
    left: 823px;
    border-radius: 8px;
    gap: 180px;
    margin-top: -250px;
    margin-left: 870px;
}

.vision{
    display: block;

}
.vision h1{
    width: 158px;
    height: 58px;
    top: 1303px;
    left: 136px;
    margin-top: -30px;
    font-family: Inter;
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: left;
    color:  #4D4D4D;
}

.vision p{
    width: 331px;
    height: 221px;
    top: 1477px;
    left: 958px;
    border-radius: 8px;
    border: 8px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 80px;
    color:#717171;
  
}
.vision img{
    width: 257px;
    height: 385px;
    top: 1250px;
    left: 749px;
    border-radius: 8px;
    border: 8px;    
    gap: 90px;  
    margin-left: 780px; 
    margin-top: -690px; 
}

.values{
    display: block;
}

.values h1{
    width: 157px;
    height: 58px;
    top: 1551px;
    left: 136px;
font-family: Inter;
font-size: 48px;
font-weight: 600;
line-height: 58px;
letter-spacing: 0em;
text-align: left;
color:  #4D4D4D;
margin-top: -150px;
}

.values img{
    width: 331px;
height: 221px;
top: 1477px;
left: 958px;
border-radius: 8px;
border: 8px;
margin-left:990px;
margin-top: -900px;
}

.values p{
    margin-left: 80px;
    width: 548px;
height: 69px;
top: 1633px;
left: 144px;
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 23px;
letter-spacing: 0em;
text-align: left;
color:#717171;

}
.values ul{
    margin-left: 50px;
    margin-top: -10px;
}
.values ul li{
    font-family: inter;
    color: #717171;
    font-size: medium;
    font-weight: 300;
}

@media only screen and (max-width: 600px) {
    .about-page {
        padding: auto;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: center; /* For Safari to work properly */ 
         justify-content: space-around;
        background-color: #FFFDF1;
        height: max-content;
    }
    .header{
        display: block;
    }
    .header h1 {
        width: 100%;
        height: max-content;
        font-family: Inter;
        font-size: 36px;
        font-weight: 600;
        line-height: 58px;
        letter-spacing: 0em;
        text-align: left;
        color:#4D4D4D;
        font-weight: 400 , 500 , 600;
        margin-left: 10%;
        margin-left: 100px;
    }
    .header .vector {
        width: auto;
        margin: auto;
        margin-left: -10%;
    }
    .vector img{
        width: 177px;
        height: 6.85px;
    }
  .aboutL{
    display: block;
  }
    .aboutL img{
        width: 360px;
        height: 270px;
        margin-left: 10px;
    }
    .right h1 {
        width: 416px;
        height: 76px;
        font-family: Inter;
        font-size: 38px;
        font-weight: 600;
        line-height: 76px;
        letter-spacing: 0em;
        margin: auto;
        text-align: left;
        color:rgb(71, 71, 71);
        margin-top: 5%;
        margin-left: 155px;
    }
    .right p {
        width: 90%;
        height: 24px;
        font-family: Inter;
        font-size: 16px;
        margin: auto;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        padding: auto;
        text-align: left;
        color:rgb(71, 71, 71);
        margin-top: 2%;
        margin-left: 90px;
    }

    .hero2{
        margin-top: -940px;
    }
    .mission{
        margin-top: 1000px;
    }
    .mission h1 {
        display: flex;
        background-color:#FFFDF1;
        gap: 16px;
        height: 500px;
        top: 181px;
        margin-left: 100px;
        left: 144px;
        gap: 16px;
        color: rgb(71, 71, 71);
        margin-bottom: 15%;
    }
    .mission p{
        width: 95%;
        height: 24px;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -490px;
        margin-left: 10px;
        color: rgb(71, 71, 71);
        padding-bottom: 90px;
    }
    .vision{
        margin-top: -310px;
    }
    .vision h1{
        display: flex;
        margin-top: 35%; 
        background-color:#FFFDF1;
        gap: 16px;
        height: 520px;
        margin-left: 100px;
        top: 181px;
        left: 144px;
        gap: 16px;
        color: rgb(71, 71, 71);
        padding-top: 20%;
        margin-bottom: 10%;
        
     
    }
    .vision p {
        width: 99%;
        height: 24px;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color:rgb(71, 71, 71);
        margin-top: -130%;
        margin-left: 2%;
        padding-bottom: 20%;
    }
    .values{
        margin-top: -30px;
    }
    .values h1 {
        display: flex;
        margin-top: 4%; 
        margin-bottom: 25%;
        background-color:#FFFDF1;
        gap: 16px;
        width: 100%;
        height: 449px;
        top: 181px;
        margin-left: 100px;
        left: 144px;
        gap: 16px;
        color: rgb(71, 71, 71);
    }
    .values p {
        width: 85%;
        height: 24px;
        font-family: Inter;
        font-size: 16px;
        margin: auto;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color:rgb(71, 71, 71);
        margin-top: -130%;
        margin-left: 8%;
        padding-bottom: 25%;
        margin-bottom: 20px;
    }
    .rectangle-box {
        height: 300px;
        width: 410px;
        background-color: #FF6C001A;
        margin-top: -400px;
        margin-left: 0px;
    }
    
    .rectangle-box h2 {
        margin: auto;
        width: 80%;
        padding-left: 5%;
        margin-top: 30%;
    }
    .rectangle-box button {
        margin: auto;
        margin-top: -10%;
    }
    .bullet {
        width: 100%;
        height: max-content;
        font-family: Inter;
        font-size: 36px;
        font-weight: 600;
        line-height: 58px;
        letter-spacing: 0em;
        text-align: left;
        color:#4D4D4D;
        font-weight: 400 , 500 , 600;

    }
    .values ul{
        margin-left: -5px;
        margin-top: -40px;
    }
    .values ul li{
        font-family: inter;
        color: #717171;
        font-size: small;
        font-weight: 200;
    }
    
}

@media only screen and (min-width: 412px)and (max-width: 420px){
    .about-page {
        padding: auto;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: center; 
         justify-content: space-around;
        background-color: #FFFDF1;
        height: max-content;
    }
    .header{
        display: block;
    }
    .header h1 {
        width: 100%;
        height: max-content;
        font-family: Inter;
        font-size: 36px;
        font-weight: 600;
        line-height: 58px;
        letter-spacing: 0em;
        text-align: left;
        color:#4D4D4D;
        font-weight: 400 , 500 , 600;
        margin-left: 100px;
    }
    .header .vector {
        width: auto;
        margin: auto;
        margin-left: -10%;
    }
    .vector img{
        width: 177px;
        height: 6.85px;
    }
  .aboutL{
    display: block;
  }
    .aboutL img{
        width: 360px;
        height: 280px;
        margin-left: 25px;
    }
    .right h1 {
        width: 416px;
        height: 76px;
        font-family: Inter;
        font-size: 38px;
        font-weight: 600;
        line-height: 76px;
        letter-spacing: 0em;
        margin: auto;
        text-align: left;
        color:rgb(71, 71, 71);
        margin-top: 5%;
        margin-left: 160px;
    }
    .right p {
        width: 90%;
        height: 24px;
        font-family: Inter;
        font-size: 16px;
        margin: auto;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        padding: auto;
        text-align: left;
        color:rgb(71, 71, 71);
        margin-top: 2%;
        margin-left: 120px;
    }

    .hero2{
        margin-top: -940px;
    }
    .mission{
        margin-top: 1000px;
    }
    .mission h1 {
        display: flex;
        background-color:#FFFDF1;
        gap: 16px;
        height: 500px;
        top: 181px;
        margin-left: 120px;
        left: 144px;
        gap: 16px;
        color: rgb(71, 71, 71);
        margin-bottom: 15%;
    }
    .mission p{
        width: 85%;
        height: 24px;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -490px;
        margin-bottom: 30px;
        margin-left: 30px;
        color: rgb(71, 71, 71);
        padding-bottom: 90px;
    }
    .vision{
        margin-top: -30px;
    }
    .vision h1{
        display: flex;
        background-color:#FFFDF1;
        gap: 16px;
        height: 520px;
        margin-left: 30px;
        gap: 16px;
        color: rgb(71, 71, 71);
        margin-top: -200px;
        margin-left: 110px;
    }
    .vision p {
        width: 90%;
        height: 24px;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color:rgb(71, 71, 71);
        margin-top: -500px;
        margin-left: 30px;
        padding-bottom: 20%;
    }
    .values{
        margin-top: 10px;
    }
    .values h1 {
        display: flex;
        margin-top: 4%; 
        margin-bottom: 25%;
        background-color:#FFFDF1;
        gap: 16px;
        width: 100%;
        height: 449px;
        top: 181px;
        margin-left: 120px;
        margin-top: -60px;
        left: 144px;
        gap: 16px;
        color: rgb(71, 71, 71);
    }
    .values p {
        width: 85%;
        height: 24px;
        font-family: Inter;
        font-size: 16px;
        margin: auto;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color:rgb(71, 71, 71);
        margin-top: -110%;
        margin-left: 40px;
        padding-bottom: 25%;
        margin-bottom: 20px;
    }
    .rectangle-box{
        width: 100%;
        height: 40%;
    }
    .rectangle-box h2 {
        margin: auto;
        width: 90%;
        padding-left: 5%;
        margin-top: 30%;
    }
    .rectangle-box button {
        width: 164px;
        height: 52px;
        padding: 14px, 32px, 14px, 32px;
        border-radius: 8px;
        background-color: #FF6C001A;
        gap: 10px;
        color: #78350F;
        border: 1px solid #78350F;
        margin-left: 180px;
    }
    .bullet {
        width: 100%;
        height: max-content;
        font-family: Inter;
        font-size: 36px;
        font-weight: 600;
        line-height: 58px;
        letter-spacing: 0em;
        text-align: left;
        color:#4D4D4D;
        font-weight: 400 , 500 , 600;
    }
    .values ul{
        margin-left: -5px;
        margin-top: -40px;
    }
    .values ul li{
        font-family: inter;
        color: #717171;
        font-size: medium;
        font-weight: 200;
    }
}
