.get-started button {
  padding: 8px 16px;
  background-color:#78350F;
  color: white;
  font: bold;
  border: none;
  cursor: pointer;
  width: Fixed (198px);
  height: Fixed (52px);
  padding: 14px, 32px, 14px, 32px;
  border-radius: 8px;
  gap: 10px;
  width: Hug (118px);
  height: Hug (37px);
  top: 76px;
  left: 1178px;
  gap: 16px;
}