.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:rgba(120, 53, 15, 0.1);
  padding: 18px;
  position: relative;
  overflow: hidden;
  height: 1px; 
  width: auto !important;
  gap: 10px;


}
  .banner .active {
    text-decoration: underline;
   }
  .left-content p {
    color: #78350F;
    font-family: Inter;
    font-size: 14px;  
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
    height: 17px;
    cursor: pointer;
    margin-left: 70px;
  }
  
  .right-content {
    display: flex;
    align-items: center;
    color: brown;
  }
  
  .right-content p {
    margin: 0 10px 0 00px;
    font-size: 14px;
    margin-left: -420px;
    gap: 448px;
    font-family: inter;
  }
  
  .right-content a {
    text-decoration: none;
    color: #78350F;
    font-family: inter;
  }
  
  .chat {
    display: flex;
    align-items: center;
    width: 189px;
    height: 49px;
    padding: 10px;
    border-radius: 8px;
    gap: 10px;
    color: #78350F;
    cursor: pointer;
    margin-left: 10px;
  }
  .chat span {
    font-weight: bold;
  }
  @media only screen and (max-width:  768px) {
    .banner {
      display: none;
    }
  }