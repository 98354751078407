.group-39813-22,
.vector-icon2 {
  position: absolute;
  object-fit: cover;
}
.group-39813-22 {
  margin: 0 !important;
  top: 26.38em;
  left: 0;
  width: 18.69em;
  height: 0.63em;
}
.vector-icon2 {
  height: 100%;
  top: 0;
  bottom: 0;
  left: 2.53em;
  max-height: 100%;
  width: 13.38em;
}
.record-keeping-icon {
  position: absolute;
  top: 0.07em;
  left: 0;
  width: 17.31em;
  height: 0.88em;
  z-index: 1;
}
.vector-parent {
  flex: 1;
  position: relative;
  height: 13.38em;
}
.retirement-programs {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0.25em 0 0;
}
.record-keeping {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 1.5em;
  line-height: 1.5em;
  font-weight: 700;
  font-family: inherit;
}
.when-joining-the {
  position: relative;
  font-size: 0.88em;
  line-height: 1.43em;
  color: #717171;
  display: inline-block;
  width: 16.79em;
}
.group1,
.membership-organizatios1,
.membership-orgs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.membership-orgs {
  width: 16.69em;
  align-items: center;
  gap: 0.5em;
}
.group1,
.membership-organizatios1 {
  align-self: stretch;
  align-items: flex-end;
  gap: 1em;
}
.membership-organizatios1 {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
  width: 18.69em;
  flex-shrink: 0;
  align-items: flex-start;
  padding: 1.5em 1.13em 0 0;
  box-sizing: border-box;
  position: relative;
  gap: 4em;

}
.tiny-students-sitting-near-boo {
  position: relative;
  width: 12.63em;
  height: 13.44em;
  object-fit: cover;
}
.retirement-programs1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 1.5em;
  line-height: 1.5em;
  font-weight: 700;
  font-family: inherit;
  white-space: pre-wrap;
}
.when-joining-the1 {
  position: relative;
  font-size: 0.88em;
  line-height: 1.43em;
  color: #717171;
  display: inline-block;
  width: 16.79em;
}
.retirement-programs-parent {
  align-self: stretch;
  height: 9.44em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1em;
  box-sizing: border-box;
  gap: 0.5em;
}
.group-39813-23 {
  position: absolute;
  margin: 0 !important;
  top: 26.38em;
  left: 0;
  width: 18.69em;
  height: 0.63em;
  object-fit: cover;
}
.membership-organizatios2 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
  width: 18.69em;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1.5em 0 0;
  box-sizing: border-box;
  position: relative;
  gap: 1em;
}
.i402042fm004c9-1-icon {
  flex: 1;
  position: relative;
  max-height: 100%;
  width: 15.25em;
  object-fit: cover;
}
.tpas1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 1.5em;
  line-height: 1.5em;
  font-weight: 700;
  font-family: inherit;
}
.when-joining-the2 {
  position: relative;
  font-size: 0.88em;
  line-height: 1.43em;
  color: #717171;
  display: inline-block;
  width: 16.79em;
}
.i402042fm004c9-1-parent,
.tpas-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
}
.i402042fm004c9-1-parent {
  height: 19.56em;
  padding: 0 1em;
  box-sizing: border-box;
  gap: 1em;
}
.group-39813-24 {
  position: absolute;
  margin: 0 !important;
  top: 26.38em;
  left: 0;
  width: 18.69em;
  height: 0.63em;
  object-fit: cover;
}
.community-hub,
.membership-organizatios3 {
  flex-shrink: 0;
  display: flex;
  box-sizing: border-box;
}
.membership-organizatios3 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
  width: 18.69em;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1.5em 0 0;
  position: relative;
  gap: 5.31em;
  overflow: hidden;
}
.community-hub {
  width: 72em;
  height: 27em;
  overflow-x: auto;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0.06em 0 0;
  gap: 1.25em;
  max-width: 100%;
  overflow: hidden;
}
.layer-1-icon {
  position: relative;
  max-height: 100%;
  width: 24.71em;
  object-fit: contain;
  max-width: 100%;
}
.frame-with-copyright-text {
  height: 38.13em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 1.25em;
  box-sizing: border-box;
  gap: 4.25em;
  text-align: center;
  color: #4d4d4d;
  font-family: Inter;
  overflow: hidden;
 
}
@media screen and (max-width: 1200px) {
  .frame-with-copyright-text {
    gap: 2.13em;
  }
}
@media screen and (max-width: 750px) {
  .frame-with-copyright-text {
    gap: 1.06em;
  }
}
@media screen and (max-width: 450px) {
  .record-keeping,
  .retirement-programs1,
  .tpas1 {
    font-size: 1.19em;
    line-height: 1.53em;
  }
}

@media only screen and (max-width: 767px) {
  .frame-with-copyright-text{
    display: flex;
    background-color: #fff;
    height: fit-content;
    width: 100%;
    overflow: hidden;
    margin-left: -30px;
    margin-top: 290px;
  }

  .community-hub {
    height: 100%;
    display: block;
    justify-content: space-evenly;
    padding: 0 0.06em 0 0;
    gap: 1.25em;
     margin-left: -80px;
    overflow: hidden;
  }
  
}
