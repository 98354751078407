/* Calculator.css */
.calculator {
    width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-family: Arial, sans-serif;
    background-color: #FFFDF1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .calculator-brand img {
    margin-left: 13em;
    /* height: 6px; */
    width: 5em;
  }
  
  h1 {
    text-align: center;
  }
  
  .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  /*  */ 
  label {
    width: 250px;
  }
  
  input {
   width: 40px;
    padding: 5px;
    border: 1px solid #ccc;
    /* border-bottom: 1px dashed black; */
    border-radius: 5px;
  }
  
  
  button1 {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #28a745;
    color: white;
    font-size: 18px;
    cursor: pointer;
  }
  
  .result {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f0f0f0;
  }
  
  strong {
    color: #28a745;
  }
  @media only screen and (min-width: 360px) and (max-width: 740px) {
    .calculator{
      margin-left: 14px;
      width:300px;
      margin-top: 2em;
      /* overflow: hidden; */
    }

    .calculator h1{
      width:max-content;
      margin-left: -20px;
      color: #4D4D4D;
      font-family: inter;
      margin-top: -15px;
    }
    .current-age{
      color:#4D4D4D;
      font-family: inter;
    }
    .retirement-age{
      color:#4D4D4D;
      font-family: inter;
    }
    .monthly-savings{
      color:#4D4D4D;
      font-family: inter;
    }
    .annual-return{
      color:#4D4D4D;
      font-family: inter;
    }
    .result{
      margin-top: 60px;
    }
   .input-group .annual-return{
    margin-bottom: 30px;
    
   }
    .calculate-btn{
      background-color:#78350F;
      margin-left: 30%;
      width:100%;

    }
    .calculator-brand img{
      margin-left: 5em;
      padding-bottom: 1em;
    }
    /* .calculator .story-paragraph h1{
      width: 80%;
    } */
    .story-title2 {
      color: #28a745;
      /* width: 80%; */
    }
    .story-paragraph {
      color: #28a745;
    }

  }
  