.saver-page{
width: auto;
height: 1800px;
overflow: hidden;
background-color:#FFFDF1;
}

.savercard-input{
margin-top: 120px;
align-items: center;
}
.saver .card{
padding: 80px;
border-radius: 30px;
margin-top: 80px;
height: 296px;
padding: 80px;
border-radius: 30px;
gap: 80px;
}
.btn-save{
    margin-top: -100px;
}
.btn-save h2{

border-radius: 20px;
gap: 8px;
margin-top: 60px;
font-size: 20px;
font-family: inter;
font-family: Inter;
font-size: 20px;
font-weight: 400;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
margin-left: 20px;
}

.green-check {
    color: green;
  }
.btn-save img{
    margin-top: -120px;
}

.button-more{
    margin-top: 30px;
    color: #78350F;
}

.button-more button{
    width: 202px;
    height: 56px;
    padding: 16px;
    border-radius: 8px;
    border: 2px;
    color:#78350F;
    border: 1px solid #78350F;
    background-color: #FFFFFF;
    font-family: Inter;
font-size: 20px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.5px;
text-align: center;

}
.right-part{
    margin-left: 620px;
    margin-bottom: 100px;
}

.right-part img{
    width: 389px;
    height:309px;
    border-radius: 4px;
    gap: 10px;
    margin-top: -450px;
    margin-bottom: 70px;
}

.closer11 .image-linker{
    margin-top: -70px;   
}

.closer11 .image-linker img{
    width: 1100px;
    overflow: hidden;
    margin-left: -80px;
}


.left-part{
    display: block;
    margin-top: -60px;
}

.left-part h1{
    color: #0F172A;
    font-family: Inter;
font-size: 36px;
font-weight: 600;
line-height: 40px;
letter-spacing: 0em;
text-align: left;

}

.gallery{
    margin-right: 350px;
    margin-bottom: 0px;
}
.images-container{
    display: flex;
}

.log{
  margin: 30px;  
}
.log .card{
height: 275px;
width: 1152px;
border-radius: 8px;
margin-top: 10px;
}

.login-container button{
    width: 67px;
    height: 19px;
    text-align: center;
    padding: 14px, 32px, 14px, 32px;
    width: 111px;
    height: 52px;
    top: 361px;
    left: 171px;
    gap: 16px;
    color: #78350F;
    border-radius: 8px;
    border: 1px;
    gap: 10px;
    background-color: #FFFEF9;
    border: 1px solid #78350F;  
}

.left-intro{
    width: 416px;
    height: 106px;
    top: 236px;
    left: 171px;
}
.left-intro h1{
    width: 416px;
height: 76px;
font-family: Inter;
font-size: 38px;
font-weight: 600;
line-height: 76px;
letter-spacing: 0em;
text-align: left;
color: #4D4D4D;
}

.left-intro p{
    width: 404px;
    height: 24px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.righti-intro{
  width: 416px;
  height: 106px;
  top: 236px;
  left: 171px;
  margin-left: 700px;
  margin-top: -20px;
}

.righti-intro h1{
  width: 404px;
  height: 24px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #4D4D4D;
}



.right-intro img{
    width: 360px;
    height: 230px;
    top: 185px;
    left: 928px;
    border-radius: 0px, 8px, 8px, 0px;
    margin-left: -500px;
    margin-top: 60px;
    cursor: none;
}
.right-intro{
    display: flex;
    align-items: flex-start;
    float: right;
    margin-top: -140px;
    margin-right: -149px;
  }
  @media only screen and (max-width: 767px) {
    .saver-page{
        height: 2300px;
        overflow: hidden;
        background-color:#FFFDF1;
    }
    .log .card{
    
        width: 270px;
        height: 400px;
        display: inline-block;
        flex-direction: column;
        margin-top: -20px;
        margin-left: -15px;
        display: block;
    }
    .login-container{
        width: 80px;
        margin-top: -100px;
        margin-left: -10px;
        margin-top: -120px;
        height:20px;
    }

    .left-intro h1{
        width: 416px;
        height: 76px;
        font-family: Inter;
        font-size: 38px;
        font-weight: 600;
        line-height: 76px;
        letter-spacing: 0em;
        text-align: left;
        color:rgb(27, 27, 26);
        margin-top: 5px;
        margin-left: -15px;
    }
    .left-intro p{
        width: 400px;
        height: 24px;
        font-family: Inter;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color:#4D4D4D;
        margin-top: -58px;
        margin-left: -15px;
    }
    .left-intro img{
        margin-left: -15px;
     
    }
    .right-intro{
        cursor: none;
        width:320px ;
    }
    .right-intro img{
        width: 280px;
        height: 200px;
        border-radius: 0px 8px 8px 0px;
        margin-left: -10px;
        margin-top: 80px;
        margin-bottom: 10px;
        cursor: none;
    }

    .saver .card {
       width: 70%;
       height :400px;
       padding: 10px;
       display: block;
       margin-top: 10px;
       padding: 40px;
    }
    .left-part h1{
        color: #0F172A;
        font-family: Inter;
        font-size: 36px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
        margin-top:30px;
    }

    .btn-save{
        margin-top: -130px;
    }
    .btn-save h2 {
        border-radius: 20px;
        gap: 8px;
        margin-top: 60px;
        font-size: 20px;
        font-family: inter;
        font-family: Inter;
        font-size: 20px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 20px;
    }
    .when-joining-the {
        position: relative;
        font-size: 0.88em;
        line-height: 1.43em;
        color: #717171;
        display: inline-block;
        width: 16.79em;
        margin-left: -20px;
        text-align: left;
    }
    .when-joining-the1 {
        position: relative;
        font-size: 0.88em;
        line-height: 1.43em;
        color: #717171;
        display: inline-block;
        width: 16.79em;
        margin-left: -20px;
        text-align: left;
    }
    .when-joining-the2 {
        position: relative;
        font-size: 0.88em;
        line-height: 1.43em;
        color: #717171;
        display: inline-block;
        width: 16.79em;
        margin-left: -30px;
        text-align: left;
    }
    .closer11 .image-linker{
        margin-top: -194px;  
        margin-left: 53px; 
    }
    .closer11 .image-linker img{
        border-radius: 8px;
        width: 120%;
        overflow: hidden;
        display: none;
    }

    .savercard-input {
        display: block;
        width: 580px;
        max-width: 90vw;
    }

    .retirement-programs {
        align-self: stretch;
        display: block;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 0.25em 0 0;
      } 
}
