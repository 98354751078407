.frame-child {
  align-self: stretch;
  height: 41.38em;
  position: relative;
  background-color: #f4f4f4;
  z-index: 0;
}
.empowering-the-workforce,
.putting-individuals-on {
  align-self: stretch;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
}
.putting-individuals-on {
  height: 1.21em;
  font-size: 3em;
  padding-right: 0.33em;
}
.empowering-the-workforce {
  height: 2.38em;
  font-size: 1.5em;
}
.putting-individuals-on-the-pat-parent {
  width: 42.19em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5.19em;
  max-width: 100%;
}
.request-a-demo {
  height: 1.21em;
  position: relative;
  font-size: 1.5em;
  display: inline-block;
  padding-left: 0.83em;
  padding-right: 0.83em;
}
.request-a-demo-wrapper {
  width: 16.44em;
  height: 3.25em;
  border-radius: 8px;
  border: 1px solid #000;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.63em;
  white-space: nowrap;
}
.frame-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.25em 0;
  box-sizing: border-box;
  gap: 2.06em;
  min-width: 42.19em;
  max-width: 100%;
}
.image-icon {
  height: 22.56em;
  width: 27.63em;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 100%;
}
.frame-parent {
  margin: 0 !important;
  position: absolute;
  top: 7.69em;
  left: 8.81em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5.31em;
  max-width: 100%;
  z-index: 1;
}

.rectangle-parent {
  align-self: stretch;
  position: relative;
  display: none;
  max-width: 100%;
}
.rectangle-parent {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.63em;
}

.individual-employee,
.login-as,
.we-are-ready {
  position: relative;
  font-size: 0.88em;
  font-weight: 500;
}
.individual-employee {
  white-space: pre-wrap;
}
.individual-employee-company-t {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.44em;
  max-width: 100%;
}
.phone-icon {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.chat-with-mykiota {
  position: relative;
  font-size: 0.88em;
}
.logo-frame {
  height: 3.06em;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.63em 0.44em 0.63em 0.63em;
  box-sizing: border-box;
  gap: 0.63em;
}
.frame-with-texts {
  justify-content: flex-start;
  gap: 0.88em;
}

.frame-with-texts
{
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
}

.kiota-logo-1 {
  height: 2.5em;
  width: 6.67em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.about-us {
  position: relative;
  font-size: 1em;
  white-space: nowrap;
}
.what-we-offer,
.who-we-power {
  flex: 1;
  white-space: nowrap;
}
.learn,
.what-we-offer,
.who-we-power {
  position: relative;
  font-size: 1em;
}
.icon-truck,
.title-category {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.title-category {
  width: 27.63em;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0.19em 0 0;
  gap: 1.25em;
}
.icon-truck {
  width: 90em;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.69em;
  color: red;
}
.get-started {
  position: relative;
  font-size: 0.88em;
  font-family: Inter;
  color: #fff;
  text-align: left;
}
.login-text {
  cursor: pointer;
  border: 0;
  padding: 0.63em 1.06em 0.63em 1.25em;
  background-color: #78350f;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.login-text:hover {
  background-color: #9e5c36;
}
.company-savings-text,
.row-content-box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.company-savings-text {
  flex-direction: column;
  padding: 0.44em 0 0;
}
.row-content-box {
  height: 3.06em;
  flex-direction: row;
  gap: 3em;
  max-width: 100%;
  text-align: left;
  color: #78350f;
  font-family: Inter;
}
.divider-rectangle-child {
  align-self: stretch;
  width: 72em;
  position: relative;
  border-radius: 8px;
  background-color: #fffef9;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
  display: none;
  max-width: 100%;
}
.caption-text-icon {
  width: 17.31em;
  height: 0.81em;
  position: relative;
  object-fit: contain;
  z-index: 2;
}
.company-savings {
  position: relative;
  font-size: 3em;
  line-height: 1.58em;
  font-weight: 600;
  font-family: inherit;
  z-index: 1;
  margin: -0.12em 0 0;
}
.top-frame {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.saving-for-retirement {
  position: relative;
  font-size: 1em;
  line-height: 1.5em;
  color: #717171;
  display: inline-block;
  max-width: 100%;
  z-index: 2;
}
.row-content {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.log-in {
  width: 3.16em;
  position: relative;
  font-size: 1em;
  font-weight: 500;
  font-family: Inter;
  color: #78350f;
  text-align: left;
  display: inline-block;
}
.asset-container,
.rectangle-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.rectangle-footer {
  flex: 1;
  border-radius: 8px;
  border: 1px solid #78350f;
  padding: 1.06em 1.69em 1em 1.94em;
  white-space: nowrap;
  z-index: 2;
}
.asset-container {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 6.94em;
  z-index: 1;
}
.schedule-a-demo {
  position: relative;
  font-size: 1em;
  font-weight: 500;
}
.arrow-right-icon {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.individual-employee-company-t1 {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.88em 2em;
  gap: 0.63em;
  z-index: 1;
}
.ellipse-frames,
.footer-big-frame,
.round-frames {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.footer-big-frame {
  flex-direction: row;
  gap: 1em;
  color: #78350f;
}
.ellipse-frames,
.round-frames {
  flex-direction: column;
}
.ellipse-frames {
  align-self: stretch;
  gap: 1.19em;
}
.round-frames {
  width: 25.81em;
  padding: 0.25em 0 0;
  box-sizing: border-box;
  min-width: 25.81em;
}
.b6cd62-e56e-4e10-bd73-eb34cf78-icon {
  width: 25.31em;
  position: relative;
  border-radius: 0 8px 8px 0;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
  z-index: 1;
}
.divider-rectangle {
  width: 72em;
  border-radius: 8px;
  background-color: #fffef9;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 1.69em;
  padding-bottom: 2%;
  box-sizing: border-box;
  gap: 1.25em;
  max-width: 100%;
  margin-top: 5%;
}
.title-category-frame,
.vector-frame {
  position: absolute;
  margin: 0 !important;
  border-radius: 50%;
}
.title-category-frame {
  width: 5em;
  height: 5em;
  top: 0;
  left: 3.75em;
  background-color: #fbbf24;
}
.vector-frame {
  width: 6.19em;
  height: 6.19em;
  right: 0;
  bottom: 0;
  background-color: #38bdf8;
}
.company-savings-text1 {
  align-self: stretch;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  position: absolute;
  left: 1.13em;
  top: -0.62em;
  width: 100%;
  transform: scale(1.089);
}
.paragraph-frame,
.wrapper-company-savings-text {
  align-self: stretch;
  align-items: center;
  position: relative;
}
.wrapper-company-savings-text {
  height: 25.44em;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.paragraph-frame {
  flex: 1;
  justify-content: flex-start;
  padding: 0 0.25em 0 0;
}
.content-box-frame,
.icon-frame,
.paragraph-frame {
  display: flex;
  flex-direction: column;
}
.icon-frame {
  align-self: stretch;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding: 10.38em 0 0;
  position: relative;
}
.content-box-frame {
  height: 44.75em;
  width: 26.19em;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0.56em;
  box-sizing: border-box;
  min-width: 26.19em;
  max-width: 100%;
}
.caption,
.main-headline,
.secondary-headline {
  align-self: stretch;
  position: relative;
  line-height: 110%;
}
.caption {
  margin: 0;
  font-size: 1.25em;
  font-weight: 500;
  font-family: inherit;
}
.main-headline,
.secondary-headline {
  font-weight: 800;
}
.main-headline {
  font-size: 4.5em;
  font-family: Roboto;
  color: #0f172a;
  display: none;
}
.secondary-headline {
  margin: 0;
  font-size: 2.25em;
  font-family: inherit;
  color: #4d4d4d;
}
.paragraph,
.top {
  align-self: stretch;
}
.top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5em;
  font-family: Inter;
}
.paragraph {
  position: relative;
  font-size: 1.25em;
  line-height: 180%;
  color: #0f172a;
  display: none;
}
.icon-jam-icons-outline-l {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
}
.icon-jam-icons-outline-l1 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button-1 {
  height: 3em;
  border-radius: 8px;
  background-color: #2563eb;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em 0.63em;
  color: #fff;
}
.icon-jam-icons-outline-l2 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text1 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
}
.icon-jam-icons-outline-l3 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button-2 {
  height: 3em;
  border-radius: 8px;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em 0.63em;
}
.icon-jam-icons-outline-l4 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
  z-index: 0;
}
.button-text2 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  z-index: 1;
}
.icon-jam-icons-outline-l5 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
  z-index: 2;
}
.text {
  position: relative;
  font-size: 0.75em;
  line-height: 140%;
}
.badge,
.button-3 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.badge {
  margin: 0 !important;
  position: absolute;
  top: 0.5em;
  left: 1.5em;
  border-radius: 12px;
  background-color: #da1e28;
  padding: 0.03em 0.34em;
  z-index: 3;
  text-align: center;
  color: #fff;
}
.button-3 {
  height: 3em;
  border-radius: 8px;
  padding: 0.75em 0.5em;
  box-sizing: border-box;
  position: relative;
}
.buttons-group,
.section-text {
  align-self: stretch;
  justify-content: flex-start;
}
.buttons-group {
  display: none;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  color: #2563eb;
}
.section-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5em;
  color: #d97706;
}
.picture-icon {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  display: none;
  z-index: 0;
}
.icon-iconoir-truck {
  height: 4em;
  width: 4em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.category {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  line-height: 110%;
  font-weight: 500;
  display: none;
}
.enrollment {
  text-transform: lowercase;
}
.title {
  margin: 0;
  position: relative;
  font-size: 1.25em;
  line-height: 110%;
  font-weight: 700;
  font-family: inherit;
}
.icon-jam-icons-outline-l6 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text3 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container3 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-stat-up {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge1 {
  height: 1.5em;
  border-radius: 4px;
  background-color: #dcfce7;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  color: #166534;
  font-family: Roboto;
}
.icon-jam-icons-outline-l7 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.title-category1,
.title-container {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.title-container {
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  color: #4d4d4d;
  font-family: Inter;
}
.title-category1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25em;
}
.picture-icon1 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  display: none;
  z-index: 0;
}
.paragraph1 {
  align-self: stretch;
  height: 4.88em;
  position: relative;
  font-size: 1em;
  line-height: 160%;
  font-family: Inter;
  color: #4d4d4d;
  display: inline-block;
}
.user-thumb-icon {
  height: 4em;
  width: 4em;
  border-radius: 100px;
  object-fit: contain;
}
.category1,
.category2 {
  align-self: stretch;
  height: 1.61em;
  position: relative;
  font-size: 1.13em;
  line-height: 160%;
  display: inline-block;
}
.category2 {
  height: 1.38em;
  font-size: 1em;
  line-height: 140%;
  color: #475569;
}
.details {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 9.25em;
}
.content,
.user-card {
  align-self: stretch;
  justify-content: flex-start;
  gap: 1em;
}
.user-card {
  display: none;
  flex-direction: row;
  align-items: center;
  padding: 1em 0 0;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.icon-jam-icons-outline-l8 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text4 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container4 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l9 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button {
  flex: 1;
  border-radius: 8px;
  background-color: #2563eb;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em;
  min-width: 5.25em;
}
.icon-jam-icons-outline-l10 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text5 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container5 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l11 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button1 {
  flex: 1;
  border-radius: 8px;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em;
  min-width: 5.25em;
  color: #2563eb;
}
.buttons-group1 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1em;
  gap: 1em;
  z-index: 2;
  color: #fff;
}
.icon-jam-icons-outline-l12 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text6 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container6 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-cancel {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.badge2,
.user-thumb-icon1 {
  margin: 0 !important;
  position: absolute;
  display: none;
}
.badge2 {
  height: 1.5em;
  top: 1em;
  left: 1em;
  border-radius: 4px;
  background-color: #f3e8ff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  z-index: 3;
  color: #6b21a8;
}
.user-thumb-icon1 {
  width: 6em;
  height: 6em;
  top: 10.75em;
  left: calc(50% - 48.25px);
  border-radius: 100px;
  object-fit: contain;
  z-index: 4;
}
.content-box {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  min-width: 12.5em;
}
.picture-icon2 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  display: none;
  z-index: 0;
}
.icon-iconoir-trophy {
  height: 4em;
  width: 4em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.schedule-demo-btn{
  color: #d97706;
  margin-bottom: 2%;
}
.span {
  content: "\2192";
  height: 50px;
}
.icon1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.category3 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  line-height: 110%;
  font-weight: 500;
  display: none;
}
.simplified-investment-selectio {
  margin-block-start: 0;
  margin-block-end: 5px;
}
.title1 {
  margin: 0;
  height: 2.45em;
  width: 16em;
  position: relative;
  font-size: 1.25em;
  line-height: 110%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
}
.icon-jam-icons-outline-l13 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text7 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container7 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-stat-up1 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge3 {
  height: 1.5em;
  border-radius: 4px;
  background-color: #dcfce7;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  color: #166534;
  font-family: Roboto;
}
.icon-jam-icons-outline-l14 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.title-category2,
.title-container1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.title-container1 {
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  color: #4d4d4d;
  font-family: Inter;
}
.title-category2 {
  height: 1.13em;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25em;
  margin: auto;
  max-width: fit-content;
  max-height: fit-content;
}
.picture-icon3 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 13.75em;
  flex-shrink: 0;
  object-fit: cover;
  display: none;
}
.paragraph22 {
  align-self: stretch;
  height: 6.5em;
  position: relative;
  font-size: 1em;
  line-height: 160%;
  font-family: Inter;
  color: #4d4d4d;
  display: inline-block;
}
.user-thumb-icon2 {
  height: 4em;
  width: 4em;
  border-radius: 100px;
  object-fit: contain;
}
.category4,
.category5 {
  align-self: stretch;
  height: 1.61em;
  position: relative;
  font-size: 1.13em;
  line-height: 160%;
  display: inline-block;
}
.category5 {
  height: 1.38em;
  font-size: 1em;
  line-height: 140%;
  color: #475569;
}
.details1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 9.25em;
}
.content1,
.user-card1 {
  align-self: stretch;
  justify-content: flex-start;
  gap: 1em;
}
.user-card1 {
  display: none;
  flex-direction: row;
  align-items: center;
  padding: 1em 0 0;
}
.content1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.icon-jam-icons-outline-l15 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text8 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container8 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l16 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button2 {
  flex: 1;
  border-radius: 8px;
  background-color: #2563eb;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em;
  min-width: 5.25em;
}
.icon-jam-icons-outline-l17 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text9 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container9 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l18 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button3 {
  flex: 1;
  border-radius: 8px;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em;
  min-width: 5.25em;
  color: #2563eb;
}
.buttons-group2 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1em;
  gap: 1em;
  z-index: 2;
  color: #fff;
}
.icon-jam-icons-outline-l19 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text10 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container10 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-cancel1 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.badge4,
.user-thumb-icon3 {
  margin: 0 !important;
  position: absolute;
  display: none;
}
.badge4 {
  height: 1.5em;
  top: 1em;
  left: 1em;
  border-radius: 4px;
  background-color: #f3e8ff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  z-index: 3;
  color: #6b21a8;
}
.user-thumb-icon3 {
  width: 6em;
  height: 6em;
  top: 10.75em;
  left: calc(50% - 48.25px);
  border-radius: 100px;
  object-fit: contain;
  z-index: 4;
}
.content-box1,
.row {
  display: flex;
  align-items: flex-start;
}
.content-box1 {
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-width: 12.5em;
}
.row {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: 3em;
}
.picture-icon4 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  display: none;
  z-index: 0;
}
.icon-iconoir-trekking {
  height: 4em;
  width: 4em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.icon2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.category6,
.title2 {
  position: relative;
  line-height: 110%;
}
.category6 {
  align-self: stretch;
  font-size: 1em;
  font-weight: 500;
  display: none;
}
.title2 {
  margin: 0;
  flex: 1;
  font-size: 1.25em;
  font-weight: 700;
  font-family: inherit;
}
.icon-jam-icons-outline-l20 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text11 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container11 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-stat-up2 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge5 {
  height: 1.5em;
  border-radius: 4px;
  background-color: #dcfce7;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  color: #166534;
  font-family: Roboto;
}
.icon-jam-icons-outline-l21 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.title-category3,
.title-container2 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.title-container2 {
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  color: #4d4d4d;
  font-family: Inter;
}
.title-category3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25em;
}
.picture-icon5 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 13.75em;
  flex-shrink: 0;
  object-fit: cover;
  display: none;
}
.paragraph3 {
  align-self: stretch;
  height: 4.88em;
  position: relative;
  font-size: 1em;
  line-height: 160%;
  font-family: Inter;
  color: #4d4d4d;
  display: inline-block;
}
.user-thumb-icon4 {
  height: 4em;
  width: 4em;
  border-radius: 100px;
  object-fit: contain;
}
.category7,
.category8 {
  align-self: stretch;
  height: 1.61em;
  position: relative;
  font-size: 1.13em;
  line-height: 160%;
  display: inline-block;
}
.category8 {
  height: 1.38em;
  font-size: 1em;
  line-height: 140%;
  color: #475569;
}
.details2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 10.88em;
}
.content2,
.user-card2 {
  align-self: stretch;
  justify-content: flex-start;
  gap: 1em;
}
.user-card2 {
  display: none;
  flex-direction: row;
  align-items: center;
  padding: 1em 0 0;
}
.content2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.icon-jam-icons-outline-l22 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text12 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container12 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l23 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button4 {
  flex: 1;
  border-radius: 8px;
  background-color: #2563eb;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em 0.56em;
  min-width: 6.75em;
}
.icon-jam-icons-outline-l24 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text13 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container13 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l25 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button5 {
  flex: 1;
  border-radius: 8px;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em 0.56em;
  min-width: 6.75em;
  color: #2563eb;
}
.buttons-group3 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1em;
  gap: 1em;
  z-index: 2;
  color: #fff;
}
.icon-jam-icons-outline-l26 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text14 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container14 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-cancel2 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.badge6,
.user-thumb-icon5 {
  margin: 0 !important;
  position: absolute;
  display: none;
}
.badge6 {
  height: 1.5em;
  top: 1em;
  left: 1em;
  border-radius: 4px;
  background-color: #f3e8ff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  z-index: 3;
  color: #6b21a8;
}
.user-thumb-icon5 {
  width: 6em;
  height: 6em;
  top: 10.75em;
  left: calc(50% - 48.25px);
  border-radius: 100px;
  object-fit: contain;
  z-index: 4;
}
.content-box2 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  min-width: 12.5em;
}
.picture-icon6 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  display: none;
  z-index: 0;
}
.icon-iconoir-piggy-bank {
  height: 4em;
  width: 4em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.icon3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.category9,
.title3 {
  position: relative;
  line-height: 110%;
}
.category9 {
  align-self: stretch;
  font-size: 1em;
  font-weight: 500;
  display: none;
}
.title3 {
  margin: 0;
  flex: 1;
  font-size: 1.25em;
  font-weight: 700;
  font-family: inherit;
}
.icon-jam-icons-outline-l27 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text15 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container15 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-stat-up3 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge7 {
  height: 1.5em;
  border-radius: 4px;
  background-color: #dcfce7;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  color: #166534;
  font-family: Roboto;
}
.icon-jam-icons-outline-l28 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.title-category4,
.title-container3 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.title-container3 {
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  color: #4d4d4d;
  font-family: Inter;
}
.title-category4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25em;
}
.picture-icon7 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 13.75em;
  flex-shrink: 0;
  object-fit: cover;
  display: none;
}
.paragraph4 {
  align-self: stretch;
  height: 6.5em;
  position: relative;
  font-size: 1em;
  line-height: 160%;
  font-family: Inter;
  color: #4d4d4d;
  display: inline-block;
}
.user-thumb-icon6 {
  height: 4em;
  width: 4em;
  border-radius: 100px;
  object-fit: contain;
}
.category10,
.category11 {
  align-self: stretch;
  height: 1.61em;
  position: relative;
  font-size: 1.13em;
  line-height: 160%;
  display: inline-block;
}
.category11 {
  height: 1.38em;
  font-size: 1em;
  line-height: 140%;
  color: #475569;
}
.details3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 10.88em;
}
.content3,
.user-card3 {
  align-self: stretch;
  justify-content: flex-start;
  gap: 1em;
}
.user-card3 {
  display: none;
  flex-direction: row;
  align-items: center;
  padding: 1em 0 0;
}
.content3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.icon-jam-icons-outline-l29 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text16 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container16 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l30 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button6 {
  flex: 1;
  border-radius: 8px;
  background-color: #2563eb;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em 0.56em;
  min-width: 6.75em;
}
.icon-jam-icons-outline-l31 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text17 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container17 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l32 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button7 {
  flex: 1;
  border-radius: 8px;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em 0.56em;
  min-width: 6.75em;
  color: #2563eb;
}
.buttons-group4 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1em;
  gap: 1em;
  z-index: 2;
  color: #fff;
}
.icon-jam-icons-outline-l33 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text18 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container18 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-cancel3 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.badge8,
.user-thumb-icon7 {
  margin: 0 !important;
  position: absolute;
  display: none;
}
.badge8 {
  height: 1.5em;
  top: 1em;
  left: 1em;
  border-radius: 4px;
  background-color: #f3e8ff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  z-index: 3;
  color: #6b21a8;
}
.user-thumb-icon7 {
  width: 6em;
  height: 6em;
  top: 10.75em;
  left: calc(50% - 48.25px);
  border-radius: 100px;
  object-fit: contain;
  z-index: 4;
}
.content-box3,
.row1 {
  display: flex;
  align-items: flex-start;
}
.content-box3 {
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-width: 12.5em;
}
.row1 {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: 3em;
}
.group-39813-2 {
  width: 45.56em;
  height: 1.5em;
  position: absolute;
  margin: 0 !important;
  top: 40.38em;
  left: 0;
  object-fit: cover;
  display: none;
  z-index: 3;
}
.row-frame {
  flex: 1;
  border-radius: 8px;
  background-color: #fffef9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2em;
  box-sizing: border-box;
  position: relative;
  gap: 3em;
  min-width: 29.63em;
  max-width: 100%;
}
.row-content-box-frame {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  gap: 4.06em;
  color: #0f172a;
  font-family: Roboto;
  margin-top: 2%;
}
.category-icon-title-box,
.row-content-box-frame,
.vector-savings-frame {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.category-icon-title-box {
  width: 75.81em;
  flex-direction: column;
  align-items: flex-end;
  gap: 3em;
}
.vector-savings-frame {
  width: 82.13em;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 1.25em;
  box-sizing: border-box;
  min-height: 75.44em;
  text-align: left;
  color: #4d4d4d;
  font-family: Inter;
}
.footer-text-child {
  height: 14.19em;
  width: 90em;
  position: relative;
  background-color: rgba(255, 108, 0, 0.1);
  display: none;
  max-width: 100%;
  z-index: 0;
}
.already-saving-with {
  margin: 0;
  position: relative;
  font-size: 2.25em;
  font-weight: 700;
  font-family: inherit;
}
.log-in1 {
  width: 3.16em;
  position: relative;
  font-size: 1em;
  font-weight: 500;
  font-family: Inter;
  color: #78350f;
  text-align: left;
  display: inline-block;
}
.about-us-text {
  height: 3.25em;
  flex: 1;
  border-radius: 8px;
  border: 1px solid #78350f;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.88em 1.81em;
  white-space: nowrap;
}
.secondary-headline1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 6.94em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-text,
.logo-frame1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-frame1 {
  margin: 0 !important;
  position: absolute;
  top: 3.25em;
  left: calc(50% - 209px);
  flex-direction: column;
  gap: 1.5em;
  z-index: 1;
}
.footer-text {
  align-self: stretch;
  height: 14.19em;
  background-color: rgba(255, 108, 0, 0.1);
  flex-direction: row;
  padding: 3.25em 31.88em 3.44em 31.94em;
  box-sizing: border-box;
  position: relative;
  gap: 0.63em;
  max-width: 100%;
}
.layer-2-icon {
  height: 100%;
  top: 0;
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.asset-4-1,
.asset-4-1-child,
.layer-2-icon {
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
}
.asset-4-1-child {
  height: 68.38%;
  top: 0;
  bottom: 31.62%;
  background-color: #f1e9da;
  z-index: 1;
}
.asset-4-1 {
  height: 7.31em;
  margin: 0 !important;
  bottom: 0;
  overflow: hidden;
}
.kiota-logo-11 {
  width: 6.67em;
  height: 2.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.all-rights-reserved,
.copyright-2023 {
  position: relative;
  font-size: 0.88em;
  line-height: 1.43em;
}
.copyright-c-e-lkiota-allrights,
.copyright-symbol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.19em;
}
.copyright-symbol {
  gap: 0.75em;
}
.social-icons,
.social-icons1,
.social-icons2 {
  object-fit: contain;
}
.social-icons,
.social-icons1,
.social-icons2,
.social-icons3 {
  height: 2em;
  width: 2em;
  position: relative;
  min-height: 2em;
}
.footer-logo,
.social-links {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.social-links {
  overflow: hidden;
  flex-direction: row;
  gap: 1em;
}
.footer-logo {
  flex-direction: column;
  gap: 2.19em;
  z-index: 2;
}
.company {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 1.25em;
  line-height: 1.4em;
  font-weight: 600;
  font-family: inherit;
}
.about-us1,
.learn1,
.what-we-offer1,
.who-we-power1 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 1.43em;
}
.col-1,
.list-items {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-items {
  align-self: stretch;
  gap: 0.75em;
}
.col-1 {
  width: 10em;
  flex-shrink: 0;
  gap: 1.5em;
  min-width: 10em;
}
.mykiota,
.support {
  position: relative;
  display: inline-block;
}
.support {
  margin: 0;
  width: 8em;
  font-size: 1.25em;
  line-height: 1.4em;
  font-weight: 600;
  font-family: inherit;
}
.mykiota {
  width: 11.43em;
  font-size: 0.88em;
  line-height: 1.43em;
}
.salesmykiotacom {
  color: inherit;
}
.sales-salesmykiotacom {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 1.43em;
}
.helpmykiotacom {
  color: inherit;
}
.supporthelpmykiotacom {
  position: relative;
  font-size: 0.88em;
  line-height: 1.43em;
  white-space: nowrap;
}
.mediamykiotacom {
  color: inherit;
}
.general-254-795-824834,
.media-mediamykiotacom {
  position: relative;
  font-size: 0.88em;
  line-height: 1.43em;
}
.col-2,
.list-items1 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-items1 {
  align-self: stretch;
  gap: 0.75em;
}
.col-2 {
  flex: 1;
  gap: 1.5em;
  min-width: 7.75em;
}
.stay-up-to {
  width: 8em;
  height: 1.4em;
  position: relative;
  font-size: 1.25em;
  line-height: 1.4em;
  font-weight: 600;
  display: inline-block;
}
.input-field-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 8px;
  background-color: #fff;
  opacity: 0.2;
}
.essential-icons-send {
  position: absolute;
  height: 45%;
  width: 7.06%;
  top: 27.5%;
  right: 4.71%;
  bottom: 27.5%;
  left: 88.24%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.your-email-address {
  position: absolute;
  top: 22.5%;
  left: 4.71%;
  font-size: 0.88em;
  line-height: 1.43em;
}
.email-input-field {
  width: 15.94em;
  height: 2.5em;
  position: relative;
  color: #d9dbe1;
}
.col-3 {
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5em;
  color: #fff;
}
.footer-big-4,
.links {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.links {
  width: 23.81em;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: flex-start;
  gap: 1.88em;
  z-index: 2;
  color: #170f49;
}
.footer-big-4 {
  align-self: stretch;
  background-color: #f1e9da;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  justify-content: space-between;
  padding: 4em 14em 4em 10.31em;
  position: relative;
  gap: 1.25em;
  text-align: left;
  color: #263238;
  font-family: Inter;
}
.kiota-company-savings,
.main-frame {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-family: Inter;
}
.main-frame {
  align-self: stretch;
  align-items: flex-start;
  max-width: 100%;
  color: #4d4d4d;
}
.kiota-company-savings {
  width: 100%;
  position: relative;
  background-color: #fffdf1;
  overflow: hidden;
  align-items: center;
  gap: 1.25em;
  letter-spacing: normal;
  color: #000;
}
@media screen and (max-width: 1150px) {
  .frame-group,
  .image-icon {
    flex: 1;
  }
  .frame-parent {
    flex-wrap: wrap;
  }
  .title-category {
    display: none;
  }
  .icon-truck {
    width: 24.31em;
  }
}
@media screen and (max-width: 1050px) {
  .putting-individuals-on {
    font-size: 2.38em;
  }
  .ready-frame {
    flex-wrap: wrap;
  }
  
  .company-savings {
    font-size: 2.38em;
    line-height: 1.61em;
  }
  .b6cd62-e56e-4e10-bd73-eb34cf78-icon,
  .round-frames {
    flex: 1;
  }
  .divider-rectangle {
    flex-wrap: wrap;
    padding-left: 1.25em;
    padding-right: 1.25em;
    padding-bottom: 1.25em;
    box-sizing: border-box;
    margin-top: 2000px;
  }
  .content-box-frame {
    flex: 1;
  }
  .main-headline {
    font-size: 3.63em;
    line-height: 1.09em;
  }
  .secondary-headline {
    font-size: 1.81em;
    line-height: 1.1em;
  }
  .row-content-box-frame {
    flex-wrap: wrap;
  }
  .already-saving-with {
    font-size: 1.81em;
  }
  .footer-big-4 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 750px) {
  .frame-group {
    min-width: 100%;
  }
  .frame-with-texts,
  .individual-employee-company-t {
    flex-wrap: wrap;
  }
  .row-content-box {
    gap: 1.5em;
  }
  .round-frames {
    min-width: 100%;
  }
  .icon-frame {
    padding-top: 6.75em;
    box-sizing: border-box;
  }
  .content-box-frame {
    min-width: 100%;
  }
  .row,
  .row1 {
    flex-wrap: wrap;
    gap: 1.5em;
  }
  .row-frame {
    gap: 1.5em;
    padding-top: 1.31em;
    padding-bottom: 1.31em;
    box-sizing: border-box;
    min-width: 100%;
  }
  .row-content-box-frame {
    gap: 2em;
  }
  .category-icon-title-box {
    gap: 1.5em;
  }
  .footer-big-4 {
    padding-left: 5.13em;
    padding-right: 7em;
    box-sizing: border-box;
  }
  .individual-employee-company-t1 {
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.88em 2em;
    gap: 0.63em;
    z-index: 1;
    margin-left: -30px;
}
}
@media screen and (max-width: 450px) {
  .putting-individuals-on {
    font-size: 1.81em;
  }
  .empowering-the-workforce {
    font-size: 1.19em;
  }
  .company-savings {
    font-size: 1.81em;
    line-height: 1.59em;
  }
  .footer-big-frame {
    flex-wrap: wrap;
  }
  .caption {
    font-size: 1em;
    line-height: 1.13em;
  }
  .main-headline {
    font-size: 2.69em;
    line-height: 1.12em;
  }
  .secondary-headline {
    font-size: 1.38em;
    line-height: 1.09em;
  }
  .paragraph,
  .title {
    font-size: 1em;
    line-height: 1.81em;
  }
  .title {
    line-height: 1.13em;
  }
  .buttons-group1,
  .user-card {
    flex-wrap: wrap;
  }
  .title1 {
    font-size: 1em;
    line-height: 1.13em;
  }
  .buttons-group2,
  .user-card1 {
    flex-wrap: wrap;
  }
  .title2 {
    font-size: 1em;
    line-height: 1.13em;
  }
  .buttons-group3,
  .user-card2 {
    flex-wrap: wrap;
  }
  .title3 {
    font-size: 1em;
    line-height: 1.13em;
  }
  .buttons-group4,
  .user-card3 {
    flex-wrap: wrap;
  }
  .row-content-box-frame {
    gap: 1em;
  }
  .already-saving-with {
    font-size: 1.38em;
  }
  .company {
    font-size: 1em;
    line-height: 1.38em;
  }
  .col-1 {
    flex: 1;
  }
  .stay-up-to,
  .support {
    font-size: 1em;
    line-height: 1.38em;
  }
  .links {
    flex-wrap: wrap;
  }
  .footer-big-4 {
    padding-left: 1.25em;
    padding-right: 1.25em;
    box-sizing: border-box;
  }
  .divider-rectangle {
    margin-top: 1%;  
  }
  .top-frame {
    margin-top: -1%;
  }
}
@media only screen and (min-width: 412px)and (max-width: 420px){
  .simplified-investment-selectio {
    margin-block-start: 0;
    margin-block-end: 5px;
}

.simplified-investment-selectio p {
    font-family: Inter;
    font-size: 16px;
    line-height: 3em;
}

}

 