@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
.home {
  background-color: #FFFDF1;
  overflow: hidden;
}
.hero {
  display: flex;
  margin-top: 0px; 
  background-color:#FFFDF1;
  gap: 16px;
  width: 1,167px;
  height: 449px;
  top: 181px;
  left: 144px;
  gap: 16px;
}
  
.text-content {
    max-width: 564px;
    max-height: 256px;
    gap: 20px;
    margin-top: 0px;
    padding: 160px, 80px;
    margin-left: 86px;

  }
    .text-content img{
      width: 277px;
      height: 12.85px;
     
    }
 .text-content h1{
    width: 564px;
    height: 116px;
    font-family: Inter;
    font-size: 38px;
    font-weight: 600;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: left;
    color:#4D4D4D;
    font-weight: 400 , 500 , 600;
    margin-top: -5px;
  }
  .text-content h2 {
    width: 496px;
    height: 98px;
    color: #717171;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: -30px;
    margin-left: 0px;
  }

.text-content p {
    width: 496px;
    height: 98px;
    color: #717171;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: -20px;
    margin-bottom: -20px;
  }
.button-container {
    max-width: 564px;
    max-height: 256px;
    gap: 20px;
    padding: 160px, 80px;
    margin-top: 30px;
  }

 .text-content h1{
  width: 564px;
  height: 116px;
  font-family: Inter;
  font-size: 38px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  color:#4D4D4D;
  font-weight: 400 , 500 , 600;
 }
 .buttonstart{
  margin-top: 300px;
  width: 198px;
  height: 52px;
  padding: 14px, 32px, 14px, 32px;
  border-radius: 8px;

 }

 .image-content {
  margin-top: -60px;
  margin-left: 100px;
}

.image-content img {
width: 587px;
height: 517.88px;
top: -390px;
left: 646px;
border-radius: 8px;
margin-top: 0;
margin-bottom: 0;
}


.button-container{
    height: Fixed (76px);
    padding: 10px;
    border-radius: 8px, 8px, 0px, 0px;
    border: 0px, 0px, 3px, 0px;
    gap: 16px;
    margin-top: 10px;
}

  .button-group {
    display: flex;
    gap: 10px; 
    width: 390px;
    height: Fixed (76px);
    padding: 10px;
    border-radius: 8px, 8px, 0px, 0px;
    border: 0px, 0px, 3px, 0px;
    gap: 16px;
    background-color:rgba(251, 191, 36, 0.25);
  }

  .button-group button {
    background-color: #FFFFFF;
    cursor: pointer;
    border-radius: 8px;
    padding: 14px, 32px, 14px, 32px;
    width: 120px;
    height: Fixed (52px);
    padding: 14px, 32px, 14px, 32px;
    border-radius: 8px;
    border: 1px;
    gap: 10px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #FBBF24;
    }

  .yellow-background button{
    color:#FFFFFF;
    background-color: #F2C94C;
    text-align: center;
    width: 120px;
  }

  .desktop {
    margin-top: 20px; /* Adjust the top margin */
    text-align: center;
  }
 .contribute{
    margin-left: 80px;
 }
  .contribute-content{
    width: 413px;
    height: 44px;
    margin-top: 24px;
    
  }
  .contribute-content h1 {
    font-family: Inter;
    font-size: 26px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0em;  
    color: rgba(0, 0, 0, 1);
    width: 806px;
    height: 14px;
    margin-top: -140px;
    text-align: left;
  }
  



  .Tools{
    width: auto;
  }

  .gallery {
    width: 80%;
    max-width: auto; 
    margin: 0 auto;
    text-align: center;
    display: block;

  }


  .gallery h2 {
    text-align: left;
    color : #4D4D4D;
    font-size: 36px;
    font-family: Inter;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    width: 479px;
    height: 44px;
    top: 1623px;
    left: 144px;
  }


  .navigate .get-started-button1{
     margin-left: 280px;
     margin-top: 40px;
  }

  .images-container {
    display: block;
    justify-content: space-between;
    align-items:center; 
    
  }  
  .text-content button {
    width: Hug (118px);
    height: Hug (37px);
    padding: 10px, 20px, 10px, 20px;
    border-radius: 8px;
    gap: 10px;
    
  }

  .first img {
    background: #FFFFFF;
    width: Fixed (299px);
    height: Fixed (432px);
    top: 1745px;
    left: 144px;
    padding: 24px, 32px, 24px, 32px;
    border-radius: 8px;
    gap: 8px;
  }

.Second img {
    width: Fixed (299px);
    height: Fixed (432px);
    top: 1745px;
    left: 571px;
    padding: 24px, 32px, 24px, 32px;
    border-radius: 8px;
    gap: 8px;
    background: #FFFFFF;
  }

.Third img {
    width: Fixed (299px);
    height: Fixed (432px);
    top: 1745px;
    left: 997px;
    padding: 24px, 32px, 24px, 32px;
    border-radius: 8px;
    gap: 8px;
    background: #FFFFFF;
}

.card {
    width: 944px; 
    height: 356px;
    top: 2725px; 
    left: 248px; 
    margin: auto;
    padding: 24px 32px; 
    border-radius: 8px; 
    gap: 8px; 
    background-color: #FFFFFF;
  }
  .card h1 {
    font-family: Inter;
    font-size: 48px;
    font-weight: 600;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: center;
    color: #131313;
    width: 835px;
    height: 128px;
  }
  
  .card p {
    width: Fixed (672px);
    height: Hug (38px);
    gap: 10px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    color: #131313;
    font-family: Inter;
    text-align: center;
  }
  
  .close{
    margin-top: 0px;
    margin-bottom: 180px;
    text-align: center;
    
  }

.navigate{
  display: flex;
  align-items: center;
  gap: 32px;
  margin-top: 10px;
  text-align: center;
  gap: 10px;
  padding: 14px, 32px, 14px, 32px;
  border-radius: 8px;
  gap: 10px;
  height:52px;
  gap: 32px;

}
.saver-schedule-demo-button{
 margin-left: 20px;
 margin-top: 40px;
 width: 134px;
 height: 20px;
 font-family: Inter;
 font-size: 16px;
 font-weight: 500;
 line-height: 19px;
 letter-spacing: 0em;
 text-align: left;
 color: #78350F;
 text-decoration: none;
}

.image-linker{

  display: block;
  bottom: 0;
  margin-top: -10px;

}

.image-linker img {
  height: auto;
  border-radius: 8px;
  margin: auto;
  width:944px;
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: auto;
  justify-content: space-between;
  text-align: left;
  flex-wrap: nowrap;
  display: flex;
  padding: 30px 10px;
  margin-top: 50px; 
  background-color: #FFFDF1;
  top: 181px;
  gap: 16px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
  width: Hug (436px);
  height: Hug (144px);
  top: 727px;
  left: 144px;
  gap: 24px;

}


.image-right{
  margin-left: auto;
  border-radius: 20px;
  border: 5px;
 
}
.all{
  width: Hug (436px);
  height: Hug (144px);
  top: 727px;
  left: 144px;
  gap: 24px;
}

.prompt{
  margin-top: 80px;
  

}
.prompt .card{
  margin: auto;
  width: 880px;
  
}


@media only screen and (min-width: 360px) and (max-width: 740px) {

  .home {
    background-color:#FFFFFF;
    overflow: hidden;
  }
  .text-content img{
    margin-top: 10px;
      height: 8.85px;
      width: 207px;
      margin-left: -50px;
  }
  .text-content h1{
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: -50px;
    width: 299px;
    height: 116px;
    font-family: Inter;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color:#4D4D4D;
  }

  .text-content h2 {
    width: 290px;
    height: 98px;
    color: #717171;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 20px;
    margin-left: -50px;
   
  }
  .get-started button {
    padding: 8px 16px;
    background-color: #78350F;
    color: white;
    border: none;
    cursor: pointer;
    width: Fixed (198px);
    height: Fixed (52px);
    padding: 14px, 32px, 14px, 32px;
    border-radius: 8px;
    gap: 10px;
    width: Hug (118px);
    height: Hug (37px);
    top: 76px;
    gap: 16px;
    margin-top: 50px;
    margin-left: -55px;
    
}
.image-content img {
  width: 307px;
  height: 317.88px;
  top: -390px;
  left: 646px;
  border-radius: 8px;
  margin-top: 0;
  margin-left: -410px;
  margin-top: 400px;
}

.contribute-content h1 {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  color: rgba(0, 0, 0, 1);
  width: 306px;
  height: 14px;
  margin-top: 200px;
  margin-left: -60px;
  text-align: center;
}
.button-group {
  display: flex;
  gap: 10px; 
  width: 322px;
  height: Fixed (76px);
  padding: 10px;
  border-radius: 8px, 8px, 0px, 0px;
  border: 0px, 0px, 3px, 0px;
  gap: 16px;
  background-color:rgba(251, 191, 36, 0.25);
  margin-top: 60px;
  margin-left: -81px;
}
.button-group button {
  background-color: #FFFFFF;
  cursor: pointer;
  border-radius: 8px;
  padding: 14px, 32px, 14px, 32px;
  width: 109px;
  height: Fixed (52px);
  padding: 14px, 32px, 14px, 32px;
  border-radius: 8px;
  border: 1px;
  gap: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #FBBF24;
  }
  .yellow-background button{
    color:#FFFFFF;
    margin-left: -5px;
    background-color: #F2C94C;
    text-align: left;
    width: 82px;
  }
  .prompt .card {
    width: 85%; 
    height:340px; 
    margin-bottom: -180px;
 }

 .prompt .card h1 {
    font-family: Inter;
    font-weight: 600;
    letter-spacing: 0em;
    line-height: 50px;
    text-align: left;
    margin-left: -20px;
    width: 360px;
    height: 100px; 
    font-size: 38px;
    color: #4D4D4D;
    margin-top: -40px;
    padding: 0;
 }
 .card p {
  width: 350px;
  height: 38px; 
  gap: 10px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  color: #131313;
  text-align: left;
  margin-left: -26px;
  margin-top: 80px;
  margin-bottom: 130px;
}


.image-linker img {
  border-radius: 8px;
  margin-top: 180px;
  width: 100%;
  overflow: hidden;
  margin-bottom: -100px;
}
.navigate{
  margin-left: 80px;
  margin-bottom: 50px;
 }
 .navigate .get-started-button1{
  margin-left: -30px;
  margin-top: -140px;
  height: 52px;
  width: 238px;
  padding: 14px, 32px, 14px, 32px;
  border: 0px, 0px, 3px, 0px;
}

.saver-schedule-demo-button {
  width: 100%;
  margin-top: -45px;
  margin-left:  10px;
}
}


@media only screen and (min-width: 412px)and (max-width: 420px){
  .home {
    background-color:#FFFFFF;
    overflow: hidden;
  }
  .text-content img{
    margin-top: 10px;
      height: 13.85px;
      width: 237px;
      margin-left: -65px;
  }
  .text-content h1{
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: -70px;
    width: 400px;
    height: 116px;
    font-family: Inter;
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color:#4D4D4D;
  }

  .text-content h2 {
    width: 390px;
    height: 98px;
    color: #717171;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: -20px;
    margin-left: -70px;
   
  }
  .get-started button {
    padding: 8px 16px;
    background-color: #78350F;
    color: white;
    font-size: 18px;
    font-family: inter;
    border: none;
    cursor: pointer;
    width: 288px;
    height: 52px;
    padding: 14px, 32px, 14px, 32px;
    border-radius: 8px;
    gap: 10px;
    top: 76px;
    gap: 16px;
    margin-top: 50px;
    margin-left: -75px;
    
}
.image-content img {
  width: 327px;
  height: 327.88px;
  top: -390px;
  left: 646px;
  border-radius: 8px;
  margin-top: 0;
  margin-left: -480px;
  margin-top: 400px;
}

.contribute-content h1 {
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  color: rgba(0, 0, 0, 1);
  width: 400px;
  height: 14px;
  margin-top: 200px;
  margin-left: -76px;
  text-align: center;
}
.button-group {
  display: flex;
  gap: 10px; 
  width: 348px;
  height: 40px;
  padding: 10px;
  border-radius: 8px, 8px, 0px, 0px;
  border: 0px, 0px, 3px, 0px;
  gap: 16px;
  background-color:rgba(251, 191, 36, 0.25);
  margin-top: 60px;
  margin-left: -71px;
}
.button-group button {
  background-color: #FFFFFF;
  cursor: pointer;
  border-radius: 8px;
  padding: 14px, 32px, 14px, 32px;
  width: 109px;
  height: Fixed (52px);
  padding: 14px, 32px, 14px, 32px;
  border-radius: 8px;
  border: 1px;
  gap: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #FBBF24;
  }
  .yellow-background button{
    color:#FFFFFF;
    margin-left: 5px;
    background-color: #F2C94C;
    text-align: left;
    width: 88px;
  }
  .prompt .card {
    width: 85%; 
    height:340px; 
    margin-bottom: -180px;
 }

 .prompt .card h1 {
    font-family: Inter;
    font-weight: 600;
    letter-spacing: 0em;
    line-height: 50px;
    text-align: left;
    margin-left: -10px;
    width: 370px;
    height: 100px; 
    font-size: 30px;
    color: #4D4D4D;
    margin-top: -40px;
    padding: 0;
 }
 .card p {
  width: 380px;
  height: 38px; 
  gap: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  color: #131313;
  text-align: left;
  margin-left: -10px;
  margin-top: 20px;
  margin-bottom: 130px;
}


.image-linker img {
  border-radius: 8px;
  margin-top: 180px;
  width: 100%;
  overflow: hidden;
  margin-bottom: -150px;
}
.navigate{
  margin-left: 80px;
  margin-bottom: 50px;
 }
 .get-started button {
  padding: 8px 16px;
  background-color: #78350F;
  color: white;
  font-size: 18px;
  font-family: inter;
  border: none;
  cursor: pointer;
  width: 208px;
  height: 62px;
  padding: 14px, 32px, 14px, 32px;
  border-radius: 8px;
  gap: 10px;
  top: 76px;
  gap: 16px;
  margin-top: 50px;
  margin-left: -65px;
}
.saver-schedule-demo-button {
  width: 120%;
  margin-top: -20px;
  margin-left:  10px;
}

}