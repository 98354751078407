.registration-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fffdf1;
  }
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .loading-container {
    background-color: #FFFDF1;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .reg-card {
    width: 600px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .logo-container {
    margin-bottom: 20px;
  }
  
  .input-group {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .input-group .input-label {
    position: absolute;
    top: -20px;
    left: 10px;
    font-weight: bold;
    background-color: white;
    padding: 0 5px;
  }
  
  .label {
    position: absolute;
    left: 10px;
    top: 14px;
    width: 60px;
    transition: all 0.2s;
    padding: 0 2px;
    z-index: 1;
    color: #666;
    padding-bottom: 0.5em;
  }
  .labell {
    position: absolute;
    left: 10px;
    top: 18px;
    width: 40px;
    transition: all 0.2s;
    padding: 0 2px;
    z-index: 1;
    color: #666;
    padding-bottom: 0.5em;
    
  }

  .labelll {
    position: absolute;
    left: 10px;
    top: 18px;
    width: 85px;
    transition: all 0.2s;
    padding: 0 2px;
    z-index: 1;
    color: #666;
    padding-bottom: 0.5em;
    
  }

  .label ::before {
    content: "";
    height: 5px;
    background-color: #ccc;
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    z-index: -1;
  }

  .labell ::before {
    content: "";
    height: 5px;
    background-color: #ccc;
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    z-index: -1;
  }

  .labelll ::before {
    content: "";
    height: 5px;
    background-color: #ccc;
    position: absolute;
    left: 0;
    top: 10px;
    width: 100%;
    z-index: -1;
  }

  .input-group .text-input:focus {
    border: 2px solid #53341e;
  }

  .input-group .text-inputt:focus {
    border: 2px solid #53341e;
  }

  .input-group .text-inputtt:focus {
    border: 2px solid #53341e;
  }

  .input-group .text-input::placeholder {
    font-size: 16px;
    opacity: 0;
    transition: all .3s;
  }

  .input-group .text-inputt::placeholder {
    font-size: 16px;
    opacity: 0;
    transition: all .3s;
  }

  .input-group .text-inputtt::placeholder {
    font-size: 16px;
    opacity: 0;
    transition: all .3s;
  }

  .input-group .text-input:focus::placeholder {
    opacity: 1;
    animation-delay: 0.2s;
  }

  .input-group .text-inputt:focus::placeholder {
    opacity: 1;
    animation-delay: 0.2s;
  }

  .input-group .text-inputtt:focus::placeholder {
    opacity: 1;
    animation-delay: 0.2s;
  }

  .input-group .text-input:focus + .label,
  .filled {
    top: -10px;
    color: #78350f;
    font-size: 14px;

  }

  .input-group .text-inputt:focus + .labell,
  .filled {
    top: -10px;
    color: #78350f;
    font-size: 14px;

  }

  .input-group .text-inputtt:focus + .labelll,
  .filled {
    top: -10px;
    color: #78350f;
    font-size: 14px;

  }

  .input-group .text-input {
    padding: 0.8rem;
    /* border: 1px solid #ccc; */
    width: 100%;
    height: 100%;
    /* border: 2px solid #78350f; */
    /* background: #666; */
    font-size: 18px;
    outline: none;
    transition: all .3s;
    color: rgb(62, 63, 63);
  }

  .input-group .text-inputt {
    padding: 0.8rem;
    /* border: 1px solid #ccc; */
    width: 100%;
    height: 100%;
    /* border: 2px solid #78350f; */
    /* background: #666; */
    font-size: 18px;
    outline: none;
    transition: all .3s;
    color: rgb(62, 63, 63);
  }

  .input-group .text-inputtt {
    padding: 0.8rem;
    /* border: 1px solid #ccc; */
    width: 100%;
    height: 100%;
    /* border: 2px solid #78350f; */
    /* background: #666; */
    font-size: 18px;
    outline: none;
    transition: all .3s;
    color: rgb(62, 63, 63);
  }

  .input-group input:focus {
    outline: none;
    border-color: #78350f;
    box-shadow: 0 0 5px rgba(120, 53, 15, 0.3);
  }
  
  .reg-card button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: #78350f;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;
  }
  
  .reg-card button:hover {
    background-color: #5c2a0b;
  }
  
  .copyright {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .reg-card {
      width: 90%;
      padding: 20px;
    }
  }