@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
.whatweoffer{
  font-family: inter;
    width: auto;
    overflow: hidden;
    background-color:#FFFEF1;
}

.individual-savins .card{
    width: 1140px;
    height: 240px;
    top: 185px;
    left: 144px;
    border-radius: 8px;
    background-color: #FFFEF9;
    margin-top: 60px;
    display: block;
}

.left-introduction{
    width: 416px;
    height: 106px;
    top: 236px;
    left: 171px;
}
.left-introduction h1{
  color: #78350F;
  width: 564px;
  height: 76px;
  margin-left: -200px;
  margin-top: -10px;
}

.left-introduction p{
  width: 404px;
  height: 24px;
  color:#4D4D4D;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
}
.right-introduction{
  margin-left: 760px;
  margin-top: -80px;
}
.right-introduction img {
  display: block;
  float: right;
  width: 280px;
}
.display-icons{
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: 160px;
  justify-content: space-evenly;
  display: flex;
  border-radius: 8px;
  height: 419px;
}
.display-icons .image-1{
   width: 380px;
   height: 230px;
  
}
.display-icons .image-2{
  width: 380px;
  height: 230px;
  
}
.display-icons .image-3{
  width: 380px;
  height: 230px;
}
.howw-it-works .card{
  width: 1120px;
  height: 240px;
  top: 185px;
  left: 144px;
  border-radius: 8px;
  background-color: #FFFEF9;
  margin-top: 60px;
  display: block;
}
.left-introduction-work{
  width: 416px;
  height: 106px;
  top: 236px;
  left: 171px;
}
.left-introduction-work h1{
  color: #78350F;
  margin-top: -10px;
  margin-left: -120px;
  width: 564px;
  height: 66px;
}
.left-introduction-work p{
  width: 404px;
  height: 24px;
  color:#4D4D4D;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0em;
  
  margin-bottom: 40px;
}
.righti-introduction {
margin-left: 600px;

}
.righti-introduction p
{
  color:#4D4D4D;
  font-family: Inter;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0em;
  margin-top:-20px;
}
.benefits-retirement{
  width: 900px;
  height: 288px;
  top: 1006px;
  margin-left: 150px;
  margin-bottom: -200px;
  text-align: center;
}
.benefits-retirement h1{
  margin-left: 200px;
  text-align: center;
  color: #78350F;
}
.how-it-works .card{
  width: 1140px;
  height: 200px;
  top: 185px;
  left: 144px;
  border-radius: 8px;
  background-color: #FFFEF9;
  margin-top: 60px;
  display: block;
}
.automate{
  width: 416px;
  height: 106px;
  top: 236px;
  left: 171px;
}

.automate h1{
  width: 364px;
  height: 36px;
  margin-top: -10px;
  margin-left: -40px;
  color: #78350F;
}
.automate p{
  width: 404px;
  height: 24px;
  color:#4D4D4D;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
}
.extension{
  margin-left: 600px;
}
.extension p{
  width: 404px;
  height: 24px;
  font-family: Inter;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0em;
  font-family: Inter;
  text-align: left;
  margin-top: -40px;
  margin-left: 120px;
  color: #4D4D4D;

}


@media only screen and (max-width: 767px) {
  .whatweoffer{
    font-family: inter;
      background-color:#FFFEF1;
  }
  
  .individual-savins .card{
    overflow: hidden;
    display: flex;
    width: 520px;
    height: 440px;
    margin-top: -30px;
}
.left-introduction{
  width: 230px;
  height: 46px;
  top: 236px;
  left: 171px;
}
.left-introduction h1{
  color: #78350F;
  width: 420px;
  height: 26px;
  margin-top: -10px;
  margin-left: -80px;
  font-size: 16px;

}
.left-introduction p{
  width: 400px;
  height: 20px;
  color:#4D4D4D;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: -20px;
  margin-top: 30px;
}
.right-introduction{
  margin-left: -240px;
  margin-top: 200px;
}
.display-icons{
  margin-top: 60px;
  margin-bottom: 440px;
  margin-left: 60px;
  justify-content: space-evenly;
  display: block;
  border-radius: 8px;
  height: 419px;
}
.howw-it-works .card{
  overflow: hidden;
  display: flex;
  width: 520px;
  height: 250px;

}
.left-introduction-work{
  width: 230px;
  height: 46px;
  top: 236px;
  left: 171px;
}
.left-introduction-work h1{
  color: #78350F;
  width: 420px;
  height: 26px;
  margin-top: -10px;
  margin-left: -80px;
  font-size: 18px;
}
.left-introduction-work p{
  width: 320px;
  height: 20px;
  color:#4D4D4D;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: -10px;
  margin-top: 30px;
}
.righti-introduction {
  margin-left:-180px;
  margin-top: 200px;
  }
  .righti-introduction p{
    width: 320px;
  height: 20px;
  color:#4D4D4D;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: -70px;
  margin-top: -20px;
}
.benefits-retirement{
  width: 600px;
  height: 288px;
  margin-left: -10px;
  text-align: left;
}

.benefits-retirement h1 {
  color: #78350F;
  width: 320px;
  line-height: normal;
  margin-top: 10px;
  margin-left: 15px;
  font-size: 19px;
  text-align: center;
}
.how-it-works .card{
  overflow: hidden;
  display: flex;
  width: 420px;
  height: 250px;
  margin-left: -40px;
  margin-top: -160px;
  margin-bottom: -80px;
}
.automate{
  width: 230px;
  height: 46px;
  top: 236px;
  left: 171px;
}
.automate h1{
  color: #78350F;
  width: 420px;
  height: 26px;
  margin-top: -10px;
  margin-left: -80px;
  font-size: 18px;
}
.automate p{
  width: 300px;
  height: 20px;
  color:#4D4D4D;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: -10px;
  margin-top: 30px;
}
@media only screen and (min-width: 412px)and (max-width: 420px){
  .whatweoffer{
    font-family: inter;
      background-color:#FFFEF1;
  }
  
  .individual-savins .card{
    overflow: hidden;
    display: flex;
    width: 520px;
    height: 440px;
    margin-top: -30px;
}
.left-introduction{
  width: 230px;
  height: 46px;
  top: 236px;
  left: 171px;
}
.left-introduction h1{
  color: #78350F;
  width: 420px;
  height: 26px;
  margin-top: -10px;
  margin-left: -40px;
  font-size: 16px;

}
.left-introduction p{
  width: 400px;
  height: 20px;
  color:#4D4D4D;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  margin-top: 30px;
}
.right-introduction{
  margin-left: -200px;
  margin-top: 200px;
}
.display-icons{
  margin-top: 60px;
  margin-bottom: 440px;
  margin-left: 90px;
  justify-content: space-evenly;
  display: block;
  border-radius: 8px;
  height: 419px;
}
.howw-it-works .card{
  overflow: hidden;
  display: flex;
  width: 520px;
  height: 250px;

}
.left-introduction-work{
  width: 230px;
  height: 46px;
  top: 236px;
  left: 171px;
}
.left-introduction-work h1{
  color: #78350F;
  width: 420px;
  height: 26px;
  margin-top: -10px;
  margin-left: -40px;
  font-size: 18px;
}
.left-introduction-work p{
  width: 320px;
  height: 20px;
  color:#4D4D4D;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  margin-top: 30px;
}
.righti-introduction {
  margin-left:-155px;
  margin-top: 200px;
  }
  .righti-introduction p{
    width: 320px;
  height: 20px;
  color:#4D4D4D;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: -70px;
  margin-top: -20px;
}
.benefits-retirement{
  width: 600px;
  height: 288px;
  margin-left: -10px;
  text-align: left;
}
.benefits-retirement h1{
  color: #78350F;
  width: 400px;
  line-height: normal;
  margin-top: -10px;
  margin-left: 15px;
  font-size: 26px;
  text-align: center;
}
.how-it-works .card{
  overflow: hidden;
  display: flex;
  width: 420px;
  height: 250px;
  margin-left: -40px;
  margin-top: -160px;
  margin-bottom: -80px;
}
.automate{
  width: 230px;
  height: 46px;
  top: 236px;
  left: 171px;
}
.automate h1{
  color: #78350F;
  width: 420px;
  height: 26px;
  margin-top: -10px;
  margin-left: -50px;
  font-size: 18px;
}
.automate p{
  width: 360px;
  height: 20px;
  color:#4D4D4D;
  font-family: Inter;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: -15px;
  margin-top: 30px;
}
}
}