@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

/* Default styles */
.navbar {
  font-family: 'Inter', sans-serif;
  display: flex;
  overflow: hidden;
  height: max-content;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color:rgba(255, 253, 241, 1);

}
.get-started-button {
  padding: 6px 12px;
  background-color: #78350F;
  color: white;
  font-weight: bold;
  border: none;
  cursor: pointer;
  width: 220px;
  height: 50px;
  font-size: 16px;
  border-radius: 8px;
  white-space: normal;
  text-align: center;
  font-family: inter;
  margin-right: -70px;
}
.navbar-brand img {
  width: 80px;
  margin-left: 65px;
}

.navbar-links {
  display: flex;
  align-items: center;
  margin-right: 100px;
  background-color:rgba(255, 253, 241, 1);

}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-right: 1rem;
}

.nav-links li:last-child {
  margin-right: 0;
}

.nav-links li a {
  color: rgba(120, 53, 15, 1);
  text-decoration: none;
}
.nav-links li a:hover {
  text-decoration: underline; 
  color: green;
  transition: 0.3s ease-in-out;
}

.services{
  color: rgba(120, 53, 15, 1);
  cursor: pointer;
}
.dropdown-content {
  display: none;
  position: absolute;
  padding: 0.5rem;
}
.dropdown-content a{
  display: block;
  text-align: left;
  width: max-content;
  margin-left: 20px;
}
.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content.show {
  display: block;
}

.get-startedd a {
  padding: 0.5rem 1rem;
  background-color:rgba(255, 253, 241, 1);
  color: #333;
  text-decoration: none;
  border-radius: 5px;
  margin-left: 100px;
}

.hamburger-menu {
  display: none;
}

.hamburger-menu.open .line:nth-child(1) {
  transform: translateY(5px) rotate(45deg);
}

.hamburger-menu.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open .line:nth-child(3) {
  transform: translateY(-5px) rotate(-45deg);
}

.line {
  width: 100%;
  height: 3px;
  background-color:#78350F;
}

/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    background-color: #FFFDF1;
  }

  .navbar-brand img {
    margin-bottom: 1rem;
    width:90px;
    margin-top: 5px;
    margin-left: 20px;
  }

  .navbar-links {
    display: none;
    width: 100%;
    padding-left: 0;
  }
  .nav-links {
    list-style: none;
    margin: 0;
    background-color: #FFFDF1;
    padding: 0;
}
  .navbar-links.show {
    display: block;
    margin-left: 20px;
    background-color: #FFFDF1;
  }
  .nav-links li {
    margin-right: 0; 
    margin-bottom: 1rem;
  }
  .get-startedd {
    display: none; 
  }
  .login-mobile {
    display: flex; 
    margin-bottom: 1rem; 
  }
  .hamburger-menu {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    width: 35px;
    height: 25px;
    cursor: pointer;
    margin-left: 280px;
    margin-top: 10px;
  }
  .dropdown {
    display: none;
  }
}

@media only screen and (min-width: 769px) {
  .login-mobile {
    display: none;
  }
  .navbar-links {
    display: flex;
  }
.navbar-links.show {
    display: block;
    margin-left: 20px;
}
  .dropdown {
    display: block; 
  }
  .nav-links {
    display: flex; 
  }
}
@media only screen and (min-width: 412px)and (max-width: 420px){
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    background-color: #FFFDF1;
  }
  .navbar-brand img {
    margin-bottom: 1rem;
    width:100px;
    margin-top: 5px;
    margin-left: 10px;
  }
  .hamburger-menu {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    width: 35px;
    height: 25px;
    cursor: pointer;
    margin-left: 320px;
    margin-top: 10px;
  }

}