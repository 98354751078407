.putting-you-on {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: 3em;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.vector-support-frame h1{
  margin-top: -220px;
  margin-left: -30px;
}
.vector-support-frame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 0.38em;
  box-sizing: border-box;
  max-width: 100%;
}
.vector-analysis-frame {
  width: 23.88em;
  position: relative;
  max-height: 100%;
  max-width: 100%;
  margin-left: 40px;
  margin-top: -80px;
  z-index: 1;
}
.benefits-frame,
.frame-employee {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.benefits-frame {
  align-self: stretch;
  gap: 1.5em;
}
.frame-employee {
  flex: 1;
  padding: 0 0 1.06em;
  box-sizing: border-box;
  min-width: 23.69em;
}

.log-in3 {
  margin: 0;
  position: relative;
  font-size: 1.5em;
  font-weight: 500;
  font-family: Inter;
  color: #000;
  text-align: left;
  white-space: nowrap;
}
.log-in-label {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
 
}
.frame-child28 {
  height: 3.25em;
  width: 23.06em;
  position: relative;
  border-radius: 8px;
  background-color: #f1f1f1;
  display: none;
  max-width: 100%;
}
.employee {
  position: relative;
  font-size: 0.94em;
  font-family: Inter;
  color: #ababab;
  text-align: left;
}
.gridiconsdropdown {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.forgot-password-text {
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.75em;
  gap: 1.25em;
  z-index: 1;
}
.forgot-password-text,
.log-in-label1,
.rectangle-parent20 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}
.rectangle-parent20 {
  flex: 1;
  border-radius: 8px;
  background-color: #f1f1f1;
  align-items: center;
  justify-content: center;
  padding: 0.88em 0;
}
.log-in-label1 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0.19em 0 0;
}
.frame-child29,
.log-in-label2 {
  border-radius: 8px;
  background-color: #f1f1f1;
}
.log-in-label2, .password {
  width: 100%;
  border: 0;
  outline: 0;
  align-self: stretch;
  height: 3.47em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.13em 0.8em;
  box-sizing: border-box;
  font-family: Inter;
  font-size: 0.94em;
  color: #ababab;
  min-width: 14.87em;
}
.frame-child29 {
  height: 3.25em;
  width: 23.06em;
  position: relative;
  display: none;
  max-width: 100%;
}
.password, .email {
  width: 5.47em;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 0.94em;
  background-color: transparent;
  height: 1.2em;
  position: relative;
  color: #ababab;
  text-align: left;
  display: inline-block;
}
.invisible-1-icon {
  height: 1.06em;
  width: 1.06em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.k-e-s-text,
.rectangle-parent21 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}
.k-e-s-text {
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0.75em;
  gap: 1.25em;
  z-index: 1;
}
.rectangle-parent21 {
  align-self: stretch;
  border-radius: 8px;
  background-color: #f1f1f1;
  align-items: center;
  justify-content: center;
  padding: 1.06em 0;
}
.forgot-password {
  position: relative;
  font-size: 0.81em;
  font-family: Inter;
  color: #78350f;
  text-align: left;
}
.circle-frame,
.retirement-plan-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.circle-frame {
  flex: 1;
  flex-direction: column;
  gap: 0.5em;
}
.retirement-plan-group {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0.19em 0 0;
  box-sizing: border-box;
}
.login {
  position: relative;
  font-size: 1em;
  font-weight: 500;
  font-family: Inter;
  color: #fff;
  text-align: left;
}
.log-in-label3 {
  cursor: pointer;
  border: 0;
  padding: 1.06em 1em 1em;
  background-color: #78350f;
  align-self: stretch;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.log-in-label3:hover {
  background-color: #9e5c36;
}
.email-frame,
.login-group {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
}
.login-group {
  margin: 0;
  width: 25.25em;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.5em 1em;
  gap: 1.5em;
  min-width: 25.25em;
}
.email-frame {
  width: 74.38em;
  flex-direction: row;
  justify-content: center;
  padding: 0 1.25em;
  gap: 10.19em;
  text-align: left;
  color: #4d4d4d;
  font-family: Inter;
}
@media screen and (max-width: 1400px) {
  .email-frame {
    gap: 5.06em;
  }
}
@media screen and (max-width: 1200px) {
  .login-group {
    flex: 1;
  }
  .email-frame {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 825px) {
  .putting-you-on {
    font-size: 2.38em;
  }
  .login-group {
    min-width: 100%;
  }
  .email-frame {
    gap: 2.56em;
  }
}
@media screen and (max-width: 450px) {
  .putting-you-on {
    font-size: 1.81em;
  }
  .frame-employee {
    min-width: 100%;
  }
  .log-in3 {
    font-size: 1.19em;
  }
  .login-group {
    padding-top: 1.25em;
    padding-bottom: 1.25em;
    box-sizing: border-box;
  }
  .email-frame {
    gap: 1.25em;
  }
  
}
@media only screen and (min-width: 360px) and (max-width: 740px){
  .kiota-logo-14 {
    height: 2.5em;
    width: 6.67em;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    margin-left: -140px;
}
.vector-support-frame h1 {
  margin-top: 20px;
  margin-left: 10px;
  text-align: left;
  line-height: normal;
  font-size: 20px;
  font-family: 'Inter';
}
.vector-analysis-frame {
  width: 14.88em;
  position: relative;
  max-height: 100%;
  max-width: 100%;
  margin-left: 20px;
  margin-top: 10px;
  z-index: 1;
}
.log-in3 {
  font-size: 2.19em;
  margin-left: 90px;
}
}