/* Success.css */

.success-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #FFFDF1;
  }
  
  .success-content {
    background: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.1);
    text-align: center;
  }
  
  .success-title {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .success-message {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  .success-info {
    color: #3f4146;
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
    text-decoration: none;
    margin-left: 0.5rem;
    justify-content: center;
    text-align: left;
  }
  
  .success-info a:hover {
    text-decoration: underline;
  }
  .Logo img{
    width: 200px;
    margin: auto;
    height: 4em;
    max-width: 30em;
    
    

  }
  .Logoo img{
    width: 100px;
    margin: auto;
    height: 2em;
    max-width: 30em;
    margin-top: -20em;
    margin-bottom: -10px;
    
    
  }
  .vector img{
    
    max-width: 8em;
    height: 10px;
    margin: auto;
    margin-top: -10px;
    
  }
  .title1 h1{
    text-align: right;
    color: #57370de3;
    font-size: 20px;
    margin-top: -7px;
  }
  strong {
    color: #553b1a;
    font-weight: 800;
  }

  