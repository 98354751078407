.picture-icon3 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  display: none;
  z-index: 0;
}
.icon3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.category5 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  line-height: 110%;
  font-weight: 500;
  display: none;
}
.enrollment {
  text-transform: lowercase;
}
.title1 {
  margin: 0;
  position: relative;
  font-size: 1.25em;
  line-height: 110%;
  font-weight: 700;
  font-family: inherit;
}
.icon-jam-icons-outline-l25 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text13 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container13 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-stat-up1 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge5 {
  height: 1.5em;
  border-radius: 4px;
  background-color: #dcfce7;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  color: #166534;
  font-family: Roboto;
}
.icon-jam-icons-outline-l26 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.title-category1,
.title-container1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.title-container1 {
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  color: #4d4d4d;
  font-family: Inter;
}
.title-category1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25em;
}
.picture-icon4 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 13.75em;
  flex-shrink: 0;
  object-fit: cover;
  display: none;
}
.paragraph3 {
  align-self: stretch;
  height: 4.88em;
  position: relative;
  font-size: 1em;
  line-height: 160%;
  font-family: Inter;
  color: #4d4d4d;
  display: inline-block;
}
.user-thumb-icon {
  height: 4em;
  width: 4em;
  border-radius: 100px;
  object-fit: cover;
}
.category6,
.category7 {
  align-self: stretch;
  height: 1.61em;
  position: relative;
  font-size: 1.13em;
  line-height: 160%;
  display: inline-block;
}
.category7 {
  height: 1.38em;
  font-size: 1em;
  line-height: 140%;
  color: #475569;
}
.details2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 9.25em;
}
.content1,
.user-card1 {
  align-self: stretch;
  justify-content: flex-start;
  gap: 1em;
}
.user-card1 {
  display: none;
  flex-direction: row;
  align-items: center;
  padding: 1em 0 0;
}
.content1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.icon-jam-icons-outline-l27 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text14 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container14 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l28 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button {
  flex: 1;
  border-radius: 8px;
  background-color: #2563eb;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em;
  min-width: 5.25em;
}
.icon-jam-icons-outline-l29 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text15 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container15 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l30 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button1 {
  flex: 1;
  border-radius: 8px;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em;
  min-width: 5.25em;
  color: #2563eb;
}
.buttons-group3 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1em;
  gap: 1em;
  z-index: 2;
  color: #fff;
}
.icon-jam-icons-outline-l31 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text16 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container16 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-cancel3 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.badge6,
.user-thumb-icon1 {
  margin: 0 !important;
  position: absolute;
  display: none;
}
.badge6 {
  height: 1.5em;
  top: 1em;
  left: 1em;
  border-radius: 4px;
  background-color: #f3e8ff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  z-index: 3;
  color: #6b21a8;
}
.user-thumb-icon1 {
  width: 6em;
  height: 6em;
  top: 10.75em;
  left: calc(50% - 48.25px);
  border-radius: 100px;
  object-fit: cover;
  z-index: 4;
}
.content-box1 {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  min-width: 12.5em;
}
.picture-icon5 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  display: none;
  z-index: 0;
}
.icon-iconoir-trophy1 {
  height: 4em;
  width: 4em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.icon4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.category8 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  line-height: 110%;
  font-weight: 500;
  display: none;
}
.simplified-investment-selectio {
  margin-block-start: 0;
  margin-block-end: 5px;
}
.title2 {
  margin: 0;
  height: 2.45em;
  width: 16em;
  position: relative;
  font-size: 1.25em;
  line-height: 110%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
}
.icon-jam-icons-outline-l32 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text17 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container17 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-stat-up2 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.badge7 {
  height: 1.5em;
  border-radius: 4px;
  background-color: #dcfce7;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  color: #166534;
  font-family: Roboto;
}
.icon-jam-icons-outline-l33 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.title-category2,
.title-container2 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.title-container2 {
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  color: #4d4d4d;
  font-family: Inter;
}
.title-category2 {
  height: 1.13em;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25em;
}
.picture-icon6 {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 13.75em;
  flex-shrink: 0;
  object-fit: cover;
  display: none;
}
.paragraph4 {
  align-self: stretch;
  height: 6.5em;
  position: relative;
  font-size: 1em;
  line-height: 160%;
  font-family: Inter;
  color: #4d4d4d;
  display: inline-block;
}
.user-thumb-icon2 {
  height: 4em;
  width: 4em;
  border-radius: 100px;
  object-fit: cover;
}
.category10,
.category9 {
  align-self: stretch;
  height: 1.61em;
  position: relative;
  font-size: 1.13em;
  line-height: 160%;
  display: inline-block;
}
.category10 {
  height: 1.38em;
  font-size: 1em;
  line-height: 140%;
  color: #475569;
}
.details3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 9.25em;
}
.content2,
.user-card2 {
  align-self: stretch;
  justify-content: flex-start;
  gap: 1em;
}
.user-card2 {
  display: none;
  flex-direction: row;
  align-items: center;
  padding: 1em 0 0;
}
.content2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.icon-jam-icons-outline-l34 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text18 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container18 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l35 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button2 {
  flex: 1;
  border-radius: 8px;
  background-color: #2563eb;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em;
  min-width: 5.25em;
}
.icon-jam-icons-outline-l36 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 1.5em;
}
.button-text19 {
  align-self: stretch;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.5px;
  line-height: 1.5em;
  font-weight: 500;
}
.text-container19 {
  height: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
  box-sizing: border-box;
}
.icon-jam-icons-outline-l37 {
  height: 1.5em;
  width: 1.5em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5em;
}
.button3 {
  flex: 1;
  border-radius: 8px;
  border: 2px solid #2563eb;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.75em;
  min-width: 5.25em;
  color: #2563eb;
}
.buttons-group4 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1em;
  gap: 1em;
  z-index: 2;
  color: #fff;
}
.icon-jam-icons-outline-l38 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button-text20 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 140%;
}
.text-container20 {
  height: 1.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.25em;
  box-sizing: border-box;
}
.icon-iconoir-cancel4 {
  height: 1em;
  width: 1em;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.badge8,
.user-thumb-icon3 {
  margin: 0 !important;
  position: absolute;
  display: none;
}
.badge8 {
  height: 1.5em;
  top: 1em;
  left: 1em;
  border-radius: 4px;
  background-color: #f3e8ff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.25em;
  box-sizing: border-box;
  z-index: 3;
  color: #6b21a8;
}
.user-thumb-icon3 {
  width: 6em;
  height: 6em;
  top: 10.75em;
  left: calc(50% - 48.25px);
  border-radius: 100px;
  object-fit: cover;
  z-index: 4;
}
.content-box2,
.row {
  display: flex;
  align-items: flex-start;
}
.content-box2 {
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-width: 12.5em;
}
.row {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: 3em;
  text-align: left;
  color: #0f172a;
  font-family: Roboto;
}
@media screen and (max-width: 750px) {
  .row {
    flex-wrap: wrap;
    gap: 1.5em;
  }
}
@media screen and (max-width: 450px) {
  .title1 {
    font-size: 1em;
    line-height: 1.13em;
  }
  .buttons-group3,
  .user-card1 {
    flex-wrap: wrap;
  }
  .title2 {
    font-size: 1em;
    line-height: 1.13em;
  }
  .buttons-group4,
  .user-card2 {
    flex-wrap: wrap;
  }
}
