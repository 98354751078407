.frame-footer-content {
  height: 3.25em;
  border-radius: 8px;
  border: 1px solid #78350f;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.88em 1.81em;
  white-space: nowrap;
}

.asset-4-1,
.kiota-logo-11 {
  overflow: hidden;
  flex-shrink: 0;
}
.asset-4-1 {
 width: 100%;
}

.kiota-logo-11 {
  width: 6.67em;
  height: 2.5em;
  position: relative;
}
.all-rights-reserved,
.copyright-2023 {
  position: relative;
  font-size: 0.88em;
  line-height: 1.43em;
}
.footer-copyright-frame,
.kiotalogonode {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.19em;
}
.footer-copyright-frame {
  gap: 0.75em;
}
.social-icons,
.social-icons1,
.social-icons2,
.social-icons3
.social-icons4 {
  height: 2em;
  width: 1.2em;
  position: relative;
  min-height: 2em;
}
.social-icons1 {
  color: #0077B5;
}
.social-icons {
  color: black;
}
.social-icons2 {
  color: black;
}
.social-icons3 {
  color: #06408b;
  /* height: 1em; */
}
.social-icons4 {
  color: red;
  height: 2em;
}
.copyright-info-frame,
.social-links {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.social-links {
  overflow: hidden;
  flex-direction: row;
  gap: 1em;
}
.copyright-info-frame {
  flex-direction: column;
  gap: 2.19em;
  z-index: 1;
}
.company {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 1.25em;
  line-height: 1.4em;
  font-weight: 600;
  font-family: inherit;
}
.about-us1,
.who-we-power1 {
  text-decoration: none;
  color: inherit;
}
.about-us1,
.learn1,
.what-we-offer1,
.who-we-power1 {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 1.43em;
}
.learn1 {
  text-decoration: none;
  color: inherit;
}
.col-1,
.list-items {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-items {
  align-self: stretch;
  gap: 0.75em;
}
.col-1 {
  width: 10em;
  flex-shrink: 0;
  gap: 1.5em;
  min-width: 10em;
  margin-left: 10em;
  /* margin-top: 1em; */
}
.mykiota,
.support {
  position: relative;
  display: inline-block;
}
.support {
 margin-top: -42px;
  width: 8em;
  font-size: 1.25em;
  line-height: 1.4em;
  font-weight: 600;
  font-family: inherit;

}
.mykiota {
  width: 11.43em;
  font-size: 0.88em;
  line-height: 1.43em;
}
.salesmykiotacom {
  color: inherit;
}
.sales-salesmykiotacom {
  align-self: stretch;
  position: relative;
  font-size: 0.88em;
  line-height: 1.43em;
}
.helpmykiotacom {
  color: inherit;
}
.supporthelpmykiotacom {
  position: relative;
  font-size: 0.88em;
  line-height: 1.43em;
  white-space: nowrap;
}
.mediamykiotacom {
  color: inherit;
}
.general-254-795-824834,
.media-mediamykiotacom {
  position: relative;
  font-size: 0.88em;
  line-height: 1.43em;
}
.col-2,
.list-items1 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.list-items1 {
  align-self: stretch;
  gap: 0.75em;
}
.col-2 {
  flex: 1;
  gap: 1.5em;
  min-width: 7.75em;
  margin-top: -12em;
  
}
.col-11 {
  margin-left: 15em;
  margin-bottom: -9em;
  }
.col-11 h3 {
  padding-bottom: 1.2em;
}
.support h3 {
  padding-bottom: 10em;
}
.what-we-offer1 {
  color: #263238;
}
.stay-up-to {
  width: 8em;
  height: 1.4em;
  position: relative;
  font-size: 1.25em;
  line-height: 1.4em;
  font-weight: 600;
  display: inline-block;
}
.input-field-bg {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 8px;
  background-color: #fff;
  opacity: 0.2;
}
.essential-icons-send {
  position: absolute;
  height: 45%;
  width: 7.06%;
  top: 27.5%;
  right: 4.71%;
  bottom: 27.5%;
  left: 88.24%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.your-email-address {
  position: absolute;
  top: 22.5%;
  left: 4.71%;
  font-size: 0.88em;
  line-height: 1.43em;
}
.email-input-field {
  width: 15.94em;
  height: 2.5em;
  position: relative;
  color: #d9dbe1;
}
.col-3 {
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5em;
  color: #fff;
}
.footer-big-4,
.frame-container,
.links {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.links {
  width: 23.81em;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.88em;
  z-index: 1;
  color: #170f49;
}
.footer-big-4,
.frame-container {
  align-self: stretch;
  text-align: left;
  font-family: Inter;
}
.footer-big-4 {
  background-color: #f1e9da;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  padding: 4em 14em 4em 10.31em;
  position: relative;
  gap: 1.25em;
  color: #263238;
}
.frame-container {
  flex-direction: column;
  justify-content: flex-start;
  color: #4d4d4d;
}
@media screen and (max-width: 1050px) {
  .gain-value-through {
    font-size: 1.81em;
  }
  .footer-big-4 {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 750px) {
  .footer-big-4 {
    padding-left: 5.13em;
    padding-right: 7em;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .gain-value-through {
    font-size: 1.38em;
  }
  .company {
    font-size: 1em;
    line-height: 1.38em;
  }
  .footer-copyright-frame .kiota-logo-11 {
    margin-top: -16em;
    margin-left: -3.7em;
    max-width: 5em;
    padding-bottom: 13em;
  }
  .copyright-2023 {
    width: 100%;
    margin-top: 4em;
    /* color: #f30d0d; */
    margin-left: -2.5em;
    
  }
  .col-11 {
    flex: 1;
    margin-bottom: -5em;
    margin-left: -9em;
    padding-top: -2em;
    margin-top: 2em;
  }
  .col-11  {
    /* color: #78350f; */
    padding: auto;
  }
  .col-11 h3 {
    margin-top: 1%;
    /* margin-bottom: 1em; */
    padding: auto;
    /* color: #0077B5; */
    /* padding-bottom: -1em; */
  }
  .support  {
    margin-top: -5.6em;
  }
  .links {
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1.88em;
    z-index: 1;
  }
  .col-2{
    padding-left: 10em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* margin-left: 4em; */
    margin-top: -11.5em;
    /* padding-top: 1em; */
  }
  .col-3{
    margin: auto;
  }
  .copyright-info-frame {
    margin: auto;
    /* padding-bottom: 1em; */
    margin-top: -2em;
    margin-left: 5em;
    
  }
  .social-links{
    margin: auto;
    /* width: 20em; */
    margin-left: -0.1em;
  }
  .stay-up-to,
  .support {
    font-size: 1em;
    line-height: 1.38em;
  }
  .links {
    flex-wrap: wrap;
  }
  .footer-big-4 {
    padding-left: 1.25em;
    padding-right: 1.25em;
    box-sizing: border-box;
  }


@media screen and (max-width: 768px) { /* Assuming 768px is the breakpoint for mobile devices */
 .footer-big-4 {
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 2em; /* Adjust as needed */
 }

 .asset-4-1,
 .copyright-info-frame {
    order: 2; /* Move these elements to the bottom */
 }
}
}