.section-text-parent {
  flex: 1;
  border-radius: 8px;
  background-color: #fffef9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2em;
  box-sizing: border-box;
  gap: 3em;
  min-width: 29.63em;
  max-width: 100%;
}
@media screen and (max-width: 750px) {
  .section-text-parent {
    gap: 1.5em;
    padding-top: 1.31em;
    padding-bottom: 1.31em;
    box-sizing: border-box;
    min-width: 100%;
  }
}
